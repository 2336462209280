import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

import './AddPolice.css';

const AddPolice = ({ parentfetchPolice}) => {
  const { lesson_id } = useParams(); // Get lessonId from the URL
  
  const { t } = useTranslation();


 const [police_types, setPoliceTypes] = useState([]);

const fetchPoliceTypes = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(api_url + '/policy-types', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result); // Verify the API response
    setPoliceTypes(result.data || result); // Adjust based on API structure
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  fetchPoliceTypes();
}, []);
  
      const [show, setShow] = useState(false);
      const handleClose = () => {
          setSuccessMessage('');
          setErrorMessage('');
          setShow(false);
      };
      const handleShow = () => setShow(true);
  
      const [formData, setFormData] = useState({
        name_en: "",
        name_ar: "",
        appearance_name_en: "",
        appearance_name_ar: "",
        policy_type_id: "",
        version: ""
        });
  
      const [successMessage, setSuccessMessage] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
      };
  
    const handleAddPolice = async (e) => {
    e.preventDefault();

    console.log(formData);

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/policies`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
            redirect: "follow",
        });

        if (response.ok) {
            setErrorMessage("");
            setSuccessMessage("Police added successfully!");
            setFormData({
                name_en: "",
                name_ar: "",
                appearance_name_en: "",
                appearance_name_ar: "",
                policy_type_id: "",
                version: ""
            });
            parentfetchPolice('');
           
        } else {
            setSuccessMessage("");
            setErrorMessage("Failed to add Police. Please try again.");
        }
    } catch (error) {
        setSuccessMessage("");
        setErrorMessage(error.message);
    }
};


  return (
    <div>
      {/* <h1> {lessonid}</h1> */}
      <label>{t('Add New Policy')}</label>
      <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')}
            </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add New Policy')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        
          <div className="mb-3">
            <label className="form-label">{t('Name (English)')}</label>
            <input
              type="text"
              name="name_en"
              className="form-control"
              onChange={handleChange}
              value={formData.name_en}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Name (Arbic)')}</label>
            <input
              type="text"
              name="name_ar"
              className="form-control"
              onChange={handleChange}
              value={formData.name_ar}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Appearance (English)')}</label>
            <input
              type="text"
              name="appearance_name_en"
              className="form-control"
              onChange={handleChange}
              value={formData.appearance_name_en}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Appearance (Arbic)')}</label>
            <input
              type="text"
              name="appearance_name_ar"
              className="form-control"
              onChange={handleChange}
              value={formData.appearance_name_ar}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Version')}</label>
            <input
              type="text"
              name="version"
              className="form-control"
              onChange={handleChange}
              value={formData.version}
            />
          </div>
          <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">{t('Policy type')}</label>
                                <select name="policy_type_id" className="form-select" onChange={handleChange} required>
                                    <option></option>
                                    {police_types && police_types.map((police, index) => (
                                        <option value={police.id}>{police.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

          

         

          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('Close')}
          </Button>
          <Button variant="primary" onClick={handleAddPolice}>
          {t('Add Policy')}
         
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPolice;
