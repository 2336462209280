import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import LeftNav from '../../Components/LeftNav/LeftNav';
import Head from '../../Components/Head/Head';


const Root = () => {

    const location = useLocation();
    const [userLoggeIn, setUserLoggedIn] = useState(false);
    
    useEffect(() => {
        
        console.log('in use Effect of Root.js');

        if (localStorage.getItem("userToken"))
        {
            console.log('in use Effect of Root.js if condition');

            setUserLoggedIn(true);
        }
    }, [location.pathname]);
    const isAssessmentPage = location.pathname.includes('Assessment');
    const isUpdatePasswordPage = location.pathname.includes('updatepassword');
    
    return (
      <div className="siteContainer">
        <div className="row m-0">
          {userLoggeIn && !isAssessmentPage && !isUpdatePasswordPage && <LeftNav />}
          <div className={`siteRight ${userLoggeIn && !isAssessmentPage && !isUpdatePasswordPage ? "col-md-9 p-0" : "col-md-12"}`}>
            {userLoggeIn && !isAssessmentPage && !isUpdatePasswordPage && <Head />}
            <Outlet />
          </div>
        </div>
      </div>
    );
}
export default Root;