import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditPolice.css';
import { useTranslation } from 'react-i18next';

const EditPolice = ({id, parentfetchPolice}) => {

    // console.log('lesson id is ' + id);
    const [police_id] = useState(id); // Use camelCase for naming consistencyws
    const [show, setShow] = useState(false); // Correct syntax for declaring state
    const { t } = useTranslation();


 const [police_types, setPoliceTypes] = useState([]);

const fetchPoliceTypes = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(api_url + '/policy-types', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result); // Verify the API response
    setPoliceTypes(result.data || result); // Adjust based on API structure
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


   

    const fetchPolice= async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(`${api_url}/policies/${police_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching Questiondetails.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
            
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["name_en"]: data.name_en,
                ["name_ar"]: data.name_ar,
                ["appearance_name_en"]: data.appearance_name_en,
                ["appearance_name_ar"]: data.appearance_name_ar,
                ["version"]: data.version,
                ["policy_type_id"]: data.policy_type_id,
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    useEffect(() => {
        // fetchQuestionTypes();
        
        fetchPoliceTypes();
        fetchPolice();
    }, []);

    
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        name_en: "",
        name_ar: "",
        appearance_name_en: "",
        appearance_name_ar: "",
        version: "",
        policy_type_id: "",
        
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdatePolice = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/policies/${police_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
            

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Police updated successfully!");
              
                parentfetchPolice('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update Police. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" />{t('Edit')}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Edit Policy')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name (English)')}</label>
                                <input type="text" name="name_en" className="form-control" onChange={handleChange} value={formData.name_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name (Arabic)')}</label>
                                <input type="text" name="name_ar" className="form-control" onChange={handleChange} value={formData.name_ar} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Appearance (English)')}</label>
                                <input type="text" name="appearance_name_en" className="form-control" onChange={handleChange} value={formData.appearance_name_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Appearance (Arabic)')}</label>
                                <input type="text" name="appearance_name_ar" className="form-control" onChange={handleChange} value={formData.appearance_name_ar} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Version')}</label>
                                <input type="text" name="version" className="form-control" onChange={handleChange} value={formData.version} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="mb-3">
                                <label className="form-label">{t('Policy type')}</label>
                                <select name="policy_type_id" className="form-select" onChange={handleChange} required  value={formData.policy_type_id}>
                                    <option></option>
                                    {police_types && police_types.map((police, index) => (
                                        <option value={police.id}>{police.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                
                      

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                    </Button>

                    <Button variant="primary" onClick={handleUpdatePolice}>
                    {t('Update Policy')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditPolice;