import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddLesson from '../../Components/AddLessonModal/AddLesson';
import EditLesson from '../../Components/EditLessonModal/EditLesson';
import { useNavigate } from 'react-router-dom';
import './Lessons.css';
import { useTranslation } from 'react-i18next';

const Lessons = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [data, setLessons] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/admin/main/users/destory/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setDeleted("User deleted successfully!");
            } else {
                setDeleted("Error!! Failed to delete user. Please try again.");
            }
        } catch (error) {
            setDeleted("Error!! " + error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.title_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.title_ar.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.code.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.lesson_type_name.toLowerCase().includes(searchVal.toLowerCase())
            );
            setLessons(filteredData);
        } else {
            fetchLessons('');
        }
    }, [searchVal]);

    const fetchLessons = async (url) => {
        setLoading(true);
        let page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessons(result.data);
            setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLessons('');
    }, []);

    const columns = React.useMemo(
        () => [
            { accessorKey: 'title_en', header: t('Title (English)'), colName: 'Title (English)' },
            { accessorKey: 'title_ar', header: t('Title (Arabic)'), colName: 'Title (Arabic)' },
            { accessorKey: 'code', header: t('Code'), colName: 'Code' },
            { accessorKey: 'lesson_type_name', header: t('Lesson Type'), colName: 'Lesson Type' },
            {
                accessorKey: 'id',
                header: t('Action'),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <EditLesson id={id} parentfetchLessons={fetchLessons} />
                            <div className="iconDel" onClick={() => handleDelete(id)}>
                                <img src="/images/fi_trash-2.png" alt="Delete" /> {t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'Action',
            },
        ],
        [t]
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleLessonRowClick = (id) => {
        navigate(`/lessons/${id}/detail`);
    };

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('All Lessons')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search Lesson')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Lesson"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <AddLesson parentfetchLessons={fetchLessons} />
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>

                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleLessonRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}

            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (
                        <li
                            key={index}
                            className={`${link.active ? "active" : ""} ${link.url === null ? "disabled" : ""}`}
                            onClick={link.url !== null ? () => fetchLessons(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Lessons;
