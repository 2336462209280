import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditEmailTemplate = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    // State to hold form data
    const [formData, setFormData] = useState({ name: "", body: "" });
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true); // Added loading state

    // Function to fetch the email template data from the API
    const fetchEmailTemplates = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const { data } = await response.json();
                setFormData({
                    name: data.name || "",
                    body: data.body || "", // Ensure body is never null
                });
                setIsLoading(false); // Set loading to false after data is fetched
            } else {
                throw new Error("Failed to fetch data");
            }
        } catch (error) {
            setErrorMessage(`Error: ${error.message}`);
            setIsLoading(false); // Ensure loading state is false if there's an error
        }
    };

    // Fetch data on component load
    useEffect(() => {
        if (id) fetchEmailTemplates();
    }, [id]);

    // Handle changes in the input field
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    // Handle changes in the CKEditor
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData((prevState) => ({ ...prevState, body: data }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage("Email template updated successfully!");
            } else {
                throw new Error("Failed to update data");
            }
        } catch (error) {
            setErrorMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className="m-5">
            <h2 className="pageTitle">{t("Update Email Template")}</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label>{t("Name")}</label>
                    <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>

                {/* Ensure CKEditor is rendered only when data is available */}
                {!isLoading && (
                    <div className="editor-container">
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.body || ""}
                            onChange={handleEditorChange}
                            config={{
                                height: "500px",
                                licenseKey: 'GPL',
                                toolbar: {
                                    items: [
                                        'undo', 'redo',
                                        '|',
                                        'heading',
                                        '|',
                                        'sourceEditing',
                                        '|',
                                        'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                        '|',
                                        'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                        '|',
                                        'link', 'uploadImage', 'blockQuote', 'codeBlock',
                                        '|',
                                        'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                                    ],
                                    shouldNotGroupWhenFull: false,
                                },
                            }}
                        />
                    </div>
                )}

                <div className="row">
                    <div className="col-md-12 mt-3">
                        {successMessage && (
                            <div className="alert alert-success">{successMessage}</div>
                        )}
                        {errorMessage && (
                            <div className="alert alert-danger">{errorMessage}</div>
                        )}
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">
                            {t("Update Email Template")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditEmailTemplate;
