import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddUser.css';
import { useTranslation } from 'react-i18next';

const AddUser = ({onfetchUsers}) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [roles, setRoles] = useState([]);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        fetchRoles();
    }, []);

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        username: "",
        password: "",
        role_id: "",
        status_id: "",
        source_id: "2"
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const fetchRoles = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/admin/main/user/roles', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                setErrorMessage("Error fetching roles.");
                return;
            }

            const rolesData = await response.json();
            setRoles(rolesData); // Assuming API returns an array of roles
        } catch (err) {
            setErrorMessage(err.message);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/users/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("User added successfully!");
                setFormData({
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone_number: "",
                    username: "",
                    password: "",
                    role_id: "",
                    status_id: "",
                    source_id: "2"
                });

                onfetchUsers('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to add user. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <label style={{marginTop:'10px'}}>{t('Add New User')}</label>
            <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')} 
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Add New User')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('First Name')}</label>
                                <input type="text" name="firstname" className="form-control" onChange={handleChange} value={formData.firstname} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Last Name')}</label>
                                <input type="text" name="lastname" className="form-control" onChange={handleChange} value={formData.lastname} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Email')}</label>
                                <input type="email" name="email" className="form-control" onChange={handleChange} value={formData.email} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Phone Number')}</label>
                                <input type="text" name="phone_number" className="form-control" onChange={handleChange} value={formData.phone_number} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Username')}</label>
                                <input type="text" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Password')}</label>
                                <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Role')}</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value="">Select Role</option>
                                    {roles.map(role => (
                                        <option key={role.id} value={role.id}>{role.title}</option>
                                    ))}
                                    {/* <option value="1">{t('Supersoft')}</option>
                                    <option value="2">{t('Professional Service')}</option>
                                    <option value="3">{t('Admin')}</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Status')}</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">{t('Active')}</option>
                                    <option value="2">{t('Inactive')}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                    </Button>

                    <Button variant="primary" onClick={handleAddUser}>
                    {t('Add User')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default AddUser;