import React, { useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditLDAPServer from '../../Components/EditLDAPServerModal/EditLDAPServer';// import Infologs from '../Info-logs/Infologs';
import Errorlogs from '../Error-logs/Errorlogs';
import Infologs from '../Info-logs/Infologs';

 const LDAPServerDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [key, setKey] = useState("details");

    const [data] = useState([]); // Mocked data for now

 

    

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('LDAP Server Detail')}</div>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="details" title="Edit LDAP Server ">
                     <EditLDAPServer id={id}  />
                </Tab>
                <Tab eventKey="Info-logs" title="Info-logs">
                     <Infologs />
                </Tab> 
                <Tab eventKey="Error-logs" title="Error-logs">
                    <Errorlogs />
                </Tab>
            </Tabs>
        </div>
    );
};

export default LDAPServerDetail;
