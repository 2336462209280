import React, { useState,useEffect } from "react";
import {  NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import "./AddLcLesson.css";
import { useTranslation } from 'react-i18next';

const AddLcLesson = ({LcLesson_id, parentfetchLcLesson}) =>  {
  // const {question_id} = useParams(); // Get questions_id from the URL
const { t } = useTranslation();

    
    const [data, setLcLesson] = useState([]);
    const [links, setLinks] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    lesson_ids: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false); // Corrected: Set as boolean `false`
  };

  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
  
    setFormData({
      ...formData,
      lesson_ids: selectedValues, // Update with selected values
    });
  };
   const fetchLessons = async (url) => {
          
          var page = 1;
          if (url !== '')
          {
              const temp = url.split('?page=');
              var page = temp[1];
          }
          
  
          try {
              const token = localStorage.getItem("userToken");
              const response = await fetch(api_url + '/lessons?page=' + page, {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, 
                  },
                });
            
                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }
  
              const result = await response.json();
              setLcLesson(result.data);
              setLinks(result.links);
          } catch (error) {
              console.error('Error fetching data:', error);
              //localStorage.removeItem("userToken");
              //localStorage.removeItem("userData");
              //window.location = "/login";
          }
      };
  
      useEffect(() => {
          fetchLessons('');
      }, []);

  const handleAddLcLesson = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/campaigns/${LcLesson_id}/attach-lessons`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage("Lc Lesson added successfully!");
        setFormData({
            lesson_ids: "",
        });
        parentfetchLcLesson("");
      } else {
        setSuccessMessage("");
        setErrorMessage("Failed to added Lc Lesson. Please try again.");
      }
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <label>{t('Add Lc Lesson')} </label>
      <br />
      <NavLink className="btnWhiteGradient" onClick={handleShow}>
        <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')} 
      </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Attach Learning campaign Lesson')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="mb-3">
        <label className="form-label">{t('Select Lesson')}</label>
        <select
        name="lesson_ids"
        className="form-control"
        onChange={handleChange}
        value={formData.lesson_ids} // Ensure `formData.user_ids` is an array
        multiple // Enables multi-select
        >
        <option value="" disabled>
            {t('Select Lesson')} {/* Adjust placeholder text */}
        </option>
        {data.map((user) => (
            <option key={user.id} value={user.id}>
            {user.title_en} ({user.code}) {/* Adjust based on actual field */}
            </option>
            ))}
            </select>

        </div>
         

          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('Close')} 
          </Button>
          <Button variant="primary" onClick={handleAddLcLesson}>
          {t('Attach Lesson')}
          </Button>
        </Modal.Footer> 
      </Modal>
    </div>
  );
};

export default AddLcLesson;
