import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import AddQuestion from '../../Components/AddQuestionModal/AddQuestion';
import EditQuestion from '../../Components/EditQuestionModal/EditQuestion';
import AnswersModal from '../../Components/AnswersModal/AnswersModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
const Questions = ({id}) => {

    const lesson_id  = id
    const { t } = useTranslation();
    const navigate = useNavigate();

    // State variables
    const [data, setLessons] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [loading, setLoading] = useState(false);

    // Handle navigation to Question page with lesson id
    const handleQuestionClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };

    // Handle delete user action
    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setDeleted("Question deleted successfully!");
                fetchQuestions('');
            }
            else {
                setDeleted("Error!! Failed to delete Question. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
            setLoading(false);
        } finally{
            setLoading(false);
        }

    }

    // Fetch questions data with pagination
    const fetchQuestions = async (url) => {
        setLoading(true);
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessons(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally{
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchQuestions('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'question_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question (English)')}
                    </div>
                ),
                colName: ' question_en',
            },
            {
                accessorKey: 'question_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Question (Arabic)')}
                    </div>
                ),
                colName: ' question_ar',
            },

            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>

                            <AnswersModal question_id={id} />

                            {/* EditQuestion component with required props */}
                            <EditQuestion id={id} parentfetchQuestion={fetchQuestions} />

                            {/* Delete button */}
                            <div className="iconDel" onClick={() => handleDelete(id)}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'Action', // Correct property spelling
            },

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-1">

            {/* <div className="pageTitle mb-4">All Questions</div> */}

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search Question')}</label>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <AddQuestion parentfetchQuestion={fetchQuestions} />
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                <div className='spinner-border' role='status' style={{
                    borderTopColor: "#6897CB",
                    borderBottomColor: "#6897CB",
                    borderLeftColor: "#6897CB",
                    marginTop: "50px"
                }}>
                </div>
            </div>
            ) : (

            <div className="bg-light px-3 py-3 userTableWrapper resTable">

                {window.innerWidth < 768 ? (
                    // Render stacked view for small screens
                    table.getRowModel().rows.map(row => (
                        <div key={row.id} className="stacked-row">
                            {row.getVisibleCells().map(cell => (
                                <div key={cell.id} className="stacked-cell">
                                    {cell.column.columnDef.colName != "ID" ? (
                                        <strong>{cell.column.columnDef.colName}: </strong>
                                    ) : ('')}

                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead className="thead-dark">
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>

                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

            </div>
            )}



            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchQuestions(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default Questions;
