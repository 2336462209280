import { useState } from "react";
import { api_url } from "../../constant/constant";
import "./AddEmailTemplate.css";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddEmailTemplate = ({ onfetchEmail }) => {
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [body, setBody] = useState('');

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleAddEmail = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/email-templates", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({name: name, body: body}),
                redirect: "follow",
            });

            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage( t("Email template added successfully!") );
                onfetchEmail();
            } else {
                setSuccessMessage("");
                setErrorMessage( t("Failed to add email template. Please try again.") );
            }
        } catch (error) {
            setSuccessMessage("");
            setErrorMessage( "Error " + error.message );
        }
    };

    return (
        <div className="m-5">
            <h2 className="pageTitle">{t("Add New Email Template")}</h2>
            <form className="addUserWrapper" onSubmit={handleAddEmail}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label className="form-label">{t("Name")}</label>
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                            />
                        </div>
                    </div>
                </div>

                {/* CK Editor */}
                <div className="row">
                    <div className="col-md-12">
                        <label>{t("Email Body")}</label>
                        <div className="editor-container">
                            <CKEditor
                                editor={ ClassicEditor }
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBody(data);
                                }}
                                config={ {
                                    height: "500px",
                                    licenseKey: 'GPL',
                                    //plugins: [ Base64UploadAdapter ],
                                    toolbar: {
                                        items: [
                                            'undo', 'redo',
                                            '|',
                                            'heading',
                                            '|',
                                            'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                                            '|',
                                            'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                                            '|',
                                            'link', 'uploadImage', 'blockQuote', 'codeBlock',
                                            '|',
                                            'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                                        ],
                                        shouldNotGroupWhenFull: false
                                    },                                
                                    //initialData: '<p>Hello from CKEditor 5 in React!</p>',
                                } }
                            />
                        </div>

                    </div>
                </div>

                {/* Success and Error Messages */}
                <div className="row">
                    <div className="col-md-12 mt-3">
                        {successMessage && (
                            <div className="alert alert-success">{successMessage}</div>
                        )}
                        {errorMessage && (
                            <div className="alert alert-danger">{errorMessage}</div>
                        )}
                    </div>
                </div>

                {/* Submit Button */}
                <div className="row mt-3">
                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">
                            {t("Add Email Template")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddEmailTemplate;

