import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { api_url } from "../../constant/constant";
import './AnswersModal.css';
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import AddAnswers from '../../Components/AddAnswersModal/AddAnswers';
import EditAnswers from '../../Components/EditAnswersModal/EditAnswers';
import { useTranslation } from 'react-i18next';


const AnswersModal = ({question_id}) => {

       const navigate = useNavigate();
       const { t } = useTranslation();



        // State variables
        const [data, setLessons] = useState([]);
        const [links, setLinks] = useState([]);
        const [deleted, setDeleted] = useState('');
    
        // Handle navigation to Question page with lesson id
        const handleAnswersClick = (id) => {
            navigate(`/question/${id}`); // Pass lesson id as a route parameter
        };
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => {
        fetchAnswers('');
        setShow(true);
    }

    const handleDelete = async(id) => {
           
        try {
            const token = localStorage.getItem("userToken");
          const response = await fetch(`${api_url}/questions/${question_id}/answers/${id}`,  {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
            });

            if (response.ok) 
            {
                setDeleted("Answers deleted successfully!");
                fetchAnswers('');
            } 
            else 
            {
                setDeleted("Error!! Failed to delete Answers. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
        }

    }

 // Fetch questions data with pagination
 const fetchAnswers = async (url) => {
     let page = 1;
     if (url) {
         const temp = url.split('?page=');
         page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
     }
 
     try {
         const token = localStorage.getItem("userToken");
          const response = await fetch(`${api_url}/questions/${question_id}/answers`,  {
             method: "GET",
             headers: {
                 "Content-Type": "application/json",
                 "Authorization": `Bearer ${token}`,
             },
         });
         
 
         if (!response.ok) {
             throw new Error(`HTTP error! status: ${response.status}`);
           }

         const result = await response.json();
         setLessons(result);
         // setLinks(result.links);
     } catch (error) {
         console.error('Error fetching data:', error);
         //localStorage.removeItem("userToken");
         //localStorage.removeItem("userData");
         //window.location = "/login";
     }
 };
 

 // Define table columns
 const columns = React.useMemo(
     () => [
         // {
         //     accessorKey: 'title_id',
         //     header: () => (
         //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
         //             <i className="bi bi-clipboard-data"></i> lesson_id (id)
         //         </div>
         //     ),
         // },
         {
             accessorKey: 'answer_en',
             header: () => (
                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                     <i className="bi bi-clipboard-data"></i>{t('Answer (English)')} 
                 </div>
             ),
             colName: ' answer_en',
         },
         {
             accessorKey: 'answer_ar',
             header: () => (
                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                     <i className="bi bi-clipboard-data"></i>{t('Answer (Arabic)')} 
                 </div>
             ),
             colName: ' answer_ar',
         },
         {
             accessorKey: 'is_correct',
             header: () => (
               <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                 <i className="bi bi-clipboard-data"></i>{t('Correct')}
               </div>
             ),
             colName: 'is_correct',
             cell: ({ row }) => (
               <div>
                 {row.getValue('is_correct') === 1 ? 'Yes' : 'No'}
               </div>
             )
           },
           
       
         {
             accessorKey: 'id', // Correct accessor key
             header: () => (
                 <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                     <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                 </div>
             ),
             cell: ({ getValue }) => {
                 const id = getValue(); // Extract the value of the 'id' column
                 return (
                     <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        
                         {/* EditQuestion component with required props */}
                         <EditAnswers question_id={question_id}  id={id} parentfetchAnswers={fetchAnswers} />
         
                         {/* Delete button */}
                         <div className="iconDel" onClick={() => handleDelete(id)}>
                             <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')} 
                         </div>
                     </div>
                 );
             },
             colName: 'id', // Correct property spelling
         },
         
     ],
     []
 );

 // Create a table instance
 const table = useReactTable({
     data,
     columns,
     getCoreRowModel: getCoreRowModel(),
 });
    


    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
            {t('Answers')}  
            </div>

            <Modal className="AnswersModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Answers')} </Modal.Title>
                </Modal.Header>

                <Modal.Body  className="adddepartmentWrapper">
                    
                <div className="p-0">

{/* <div className="pageTitle mb-4">All Answers</div> */}

<div className="primaryBlueBox p-5 mb-4">
    <div className="row">
        <div className="col-md-4">
            <label className="form-label">{t('Search Answers')}</label>
            <div className="input-group">
                <input type="text" className="form-control" placeholder="Search" aria-label="Search" />
                <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                    <i className="bi bi-search"></i>
                </button>
            </div>
        </div>
        <div className="col-md-1">
            <div className="vrLine"></div>
        </div>
        <div className="col-md-7">
            <AddAnswers question_id={question_id} parentfetchAnswers={fetchAnswers} />
        </div>
    </div>
</div>

{ deleted && (
    <div className="alert alert-success alert-dismissible fade show" role="alert">
        {deleted}
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
)}
<div className="bg-light px-3 py-3 userTableWrapper resTable">
   
   {window.innerWidth < 768 ? (
           // Render stacked view for small screens
           table.getRowModel().rows.map(row => (
             <div key={row.id} className="stacked-row">
               {row.getVisibleCells().map(cell => (
                 <div key={cell.id} className="stacked-cell">
                   {cell.column.columnDef.colName != "ID" ? (
                       <strong>{cell.column.columnDef.colName}: </strong>
                   ) : ('')}
                   
                   {flexRender(cell.column.columnDef.cell, cell.getContext())}
                 </div>
               ))}
             </div>
           ))
         ) : (
   <div className="1">
           <table className="table table-bordered table-hover">
               <thead className="thead-dark">
                   {table.getHeaderGroups().map(headerGroup => (
                       <tr key={headerGroup.id}>
                           {headerGroup.headers.map(header => (
                               <th key={header.id}>
                                   {flexRender(header.column.columnDef.header, header.getContext())}
                               </th>
                           ))}
                       </tr>
                   ))}
               </thead>
   
               <tbody>
                   {table.getRowModel().rows.map(row => (
                       <tr key={row.id}>
                           {row.getVisibleCells().map(cell => (
                               <td key={cell.id}>
                                   {flexRender(cell.column.columnDef.cell, cell.getContext())}
                               </td>
                           ))}
                       </tr>
                   ))}
               </tbody>
           </table>
       </div>
         )}
       
   </div>
   


    <div className="navigationWrapper">
        <ul>
        {links.map((link, index) => (
            
            <li key={index} 
                className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                onClick={link.url !== null ? () => fetchAnswers(link.url) : undefined}
                >
                <div dangerouslySetInnerHTML={{ __html: link.label }} />
            </li>
        ))}
        </ul>
    </div>

</div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default AnswersModal;