import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

const AddOption = ({ question_id, parentfetchOptions }) => {
    console.log("Question ID from URL:", question_id);


    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        option_text_en: "",
        option_text_ar: "",
        is_correct: 0, // Checkbox default value
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [options, setOptions] = useState([]);

    const handleClose = () => {
        setSuccessMessage("");
        setErrorMessage("");
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
        });
    };


    const handleGetOptions = async () => {
        try {
            console.log("Fetching options for question ID:", question_id); // Debug log
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${question_id}/options`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setOptions(data);
                setErrorMessage("");
            } else {
                setErrorMessage("Failed to fetch options. Please try again.");
                setOptions([]);
            }
        } catch (error) {
            setErrorMessage(error.message);
            setOptions([]);
        }
    };

    useEffect(() => {
        if (question_id) {
            handleGetOptions();
        } else {
            console.error("Question ID is missing!"); // Log an error if ID is undefined
        }
    }, [question_id]);

    const handleAddOptions = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + `/questions/${question_id}/options`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage("Option added successfully!");
                setFormData({
                    option_text_en: "",
                    option_text_ar: "",

                });
                parentfetchOptions('');
            }
            else {
                setSuccessMessage("");
                setErrorMessage("Failed to add option. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>
            <label>{t('Add New Option')} </label>
            <br />
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add Option" />{t('Add')}
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Add New Option')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="mb-3">
                        <label className="form-label">{t('Name (English)')}</label>
                        <input
                            type="text"
                            name="option_text_en"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.option_text_en}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">{t('Name (Arabic)')} </label>
                        <input
                            type="text"
                            name="option_text_ar"
                            className="form-control"
                            onChange={handleChange}
                            value={formData.option_text_ar}
                        />
                    </div>

                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Close')}
                    </Button>
                    <Button variant="primary" onClick={handleAddOptions}>
                        {t('Add Option')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddOption;
