import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

function EmailTemplate({LearningManager_id}) {
    const compaign_id = LearningManager_id;
    const [email, setEmail] = useState("");
    const [selectedEnglishTemplate, setSelectedEnglishTemplate] = useState("");
    const [selectedServer, setSelectedServer] = useState("");
    const [selectedemailservers, setSelectedEmailServer] = useState([]);

    const [selectedArabicTemplate, setSelectedArabicTemplate] = useState("");
    const [englishTemplates, setEnglishTemplates] = useState([]);
    const [arabicTemplates, setArabicTemplates] = useState([]);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    // Fetch templates on component mount
    const fetchEmail = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-templates`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            // Assuming the API response structure is correct
            setArabicTemplates(result.arabic_templates || []);
            setEnglishTemplates(result.english_templates || []);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError("Failed to fetch email templates. Please try again later.");
        }
    };
    const fetchEmailServer = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaigns-servers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const result = await response.json();
    
            // Ensure email_servers is processed correctly
            if (result.status === "success" && Array.isArray(result.email_servers)) {
                setSelectedEmailServer(result.email_servers);
            } else {
                setSelectedEmailServer([]);
                setError("Invalid response structure.");
            }
        } catch (error) {
            console.error("Error fetching email servers:", error);
            setError("Failed to fetch email servers. Please try again later.");
            setSelectedEmailServer([]);
        }
    };
    

    useEffect(() => {
        fetchEmail();
        fetchEmailServer();
    }, []);

    const handleSaveEmail = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");
    
        // Validate if all fields are filled in
        if ( !selectedEnglishTemplate || !selectedArabicTemplate || !selectedemailservers) {
            setError(t("Please fill in all fields."));
            return;
        }
    
        setLoading(true);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/learning-campaign-settings`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    campaign_id:compaign_id,
                    email_template_en_id: selectedEnglishTemplate,
                    email_template_ar_id: selectedArabicTemplate,
                    server_id: selectedServer, // Include the selected email server
                }),
            });
    
            const data = await response.json();
    
            if (data.status === "success") {
                setSuccess(t("Email  saved successfully."));
            } else {
                setError(data.message || t("Failed to save email ."));
            }
        } catch (err) {
            setError(t("An error occurred while saving email ."));
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="container" style={{ margin: "0 auto", marginTop: "5%" }}>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}

            <form onSubmit={handleSaveEmail}>
             

                <div className="mb-3" style={{ width: "30%" }}>
                    <label htmlFor="englishTemplate" className="form-label">
                        {t("Email Template (English)")}
                    </label>
                    <select
                        className="form-select"
                        id="englishTemplate"
                        value={selectedEnglishTemplate}
                        onChange={(e) => setSelectedEnglishTemplate(e.target.value)}
                        required
                    >
                        <option value="">{t("English Template")}</option>
                        {englishTemplates.map((template) => (
                            <option key={template.id} value={template.id}>
                                {template.title}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3" style={{ width: "30%" }}>
                    <label htmlFor="arabicTemplate" className="form-label">
                        {t("Email Template (عربي)")}
                    </label>
                    <select
                        className="form-select"
                        id="arabicTemplate"
                        value={selectedArabicTemplate}
                        onChange={(e) => setSelectedArabicTemplate(e.target.value)}
                        required
                    >
                        <option value="">{t("Arabic Template")}</option>
                        {arabicTemplates.map((template) => (
                            <option key={template.id} value={template.id}>
                                {template.title}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="mb-3" style={{ width: "30%" }}>
                <label htmlFor="email_servers" className="form-label">
                    {t("Email Server")}
                </label>
                <select
                    className="form-select"
                    id="email_servers"
                    value={selectedServer} // Bind this to the selected server's ID
                    onChange={(e) => setSelectedServer(e.target.value)}
                    required
                >
                    <option value="">{t("Select Email Server")}</option>
                    {Array.isArray(selectedemailservers) &&
                        selectedemailservers.map((server) => (
                            <option key={server.id} value={server.id}>
                                {server.name}
                            </option>
                        ))}
                </select>
            </div>



                <div style={{ display: "flex", gap: "10px" }}>
    <Button variant="secondary" type="button">
        {t("Close")}
    </Button>
    <Button variant="primary" type="submit" disabled={loading}>
        {loading ? t("Saving...") : t("Save ")}
    </Button>
</div>

            </form>
        </div>
    );
}

export default EmailTemplate;
