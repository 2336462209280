import { Modal, Button } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import { api_url } from "../../constant/constant";
import './QuestionOptions.css';
import { useNavigate } from 'react-router-dom';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import AddOption from '../../Components/AddQuestionOptionModal/AddOption';
import { useTranslation } from 'react-i18next';


const QuestionOptions = ({ question_id }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();



    // State variables
    const [data, setOptions] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [searchValue, setSearchValue] = useState('');

    // Handle navigation to Question page with lesson id
    const handleAnswersClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleDelete = async (id) => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${question_id}/options/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setDeleted("Answers deleted successfully!");
                fetchOptions('');
            }
            else {
                setDeleted("Error!! Failed to delete Answers. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
        }

    }


    useEffect(() => {
        if (searchValue !== '') {
            const filteredData = data.filter((item) =>
                item.option_text_en.toLowerCase().includes(searchValue.toLowerCase())
                || item.option_text_ar.toLowerCase().includes(searchValue.toLowerCase())
            );
            setOptions(filteredData);
        } else {
            fetchOptions('');
        }
    }, [searchValue]);


    // Fetch questions data with pagination
    const fetchOptions = async (url) => {
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${question_id}/options`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setOptions(result);
            // setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            //localStorage.removeItem("userToken");
            //localStorage.removeItem("userData");
            //window.location = "/login";
        }
    };


    useEffect(() => {
        fetchOptions('');
    }, [question_id]);




    // Define table columns
    const columns = React.useMemo(
        () => [

            {
                accessorKey: 'option_text_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name (English)')}

                    </div>
                ),
                colName: ' option_text_en',
            },
            {
                accessorKey: 'option_text_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name (Arabic)')}
                    </div>
                ),
                colName: ' option_text_ar',
            },


        ],
        []
        // [fetchOptions, handleDelete, question_id]
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });



    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <i class="bi bi-list me-1"></i>
                {t('Options')}
            </div>

            <Modal className="AnswersModal" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Options')} </Modal.Title>
                </Modal.Header>

                <Modal.Body className="adddepartmentWrapper">

                    <div className="p-1">

                        {/* <div className="pageTitle mb-4">All Answers</div> */}

                        <div className="primaryBlueBox p-5 mb-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="form-label">{t('Search Options')}</label>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search" aria-label="Search"
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                        <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                            <i className="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <div className="vrLine"></div>
                                </div>
                                <div className="col-md-7">
                                    <AddOption question_id={question_id} parentfetchOptions={fetchOptions} />
                                </div>
                            </div>
                        </div>

                        {deleted && (
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                {deleted}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        )}
                        <div className="bg-light px-3 py-3 userTableWrapper resTable">

                            {window.innerWidth < 768 ? (
                                // Render stacked view for small screens
                                table.getRowModel().rows.map(row => (
                                    <div key={row.id} className="stacked-row">
                                        {row.getVisibleCells().map(cell => (
                                            <div key={cell.id} className="stacked-cell">
                                                {cell.column.columnDef.colName != "ID" ? (
                                                    <strong>{cell.column.columnDef.colName}: </strong>
                                                ) : ('')}

                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </div>
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div className="1">
                                    <table className="table table-bordered table-hover">
                                        <thead className="thead-dark">
                                            {table.getHeaderGroups().map(headerGroup => (
                                                <tr key={headerGroup.id}>
                                                    {headerGroup.headers.map(header => (
                                                        <th key={header.id}>
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>

                                        <tbody>
                                            {table.getRowModel().rows.map(row => (
                                                <tr key={row.id}>
                                                    {row.getVisibleCells().map(cell => (
                                                        <td key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                        </div>



                        <div className="navigationWrapper">
                            <ul>
                                {links.map((link, index) => (

                                    <li key={index}
                                        className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                                        onClick={link.url !== null ? () => fetchOptions(link.url) : undefined}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: link.label }} />
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>


                </Modal.Footer>
            </Modal>
        </div>
    );

}

export default QuestionOptions;