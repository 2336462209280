import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const AddLicenseManager = ({ parentFetchLicenses }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [licenseKey, setLicenseKey] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [validationKey, setValidationKey] = useState(""); // New state to store validation key

    const handleClose = () => {
        setSuccessMessage("");
        setErrorMessage("");
        setValidationKey(""); // Reset validation key on modal close
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleLicenseChange = (e) => {
        setLicenseKey(e.target.value);
    };

    const handleValidateLicense = async (e) => {
        e.preventDefault();
        try 
        {
            
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/validate-license`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ license_key: licenseKey }),
                credentials: 'include',
            });
            
            if (response.ok) {
                const data = await response.json();

                console.log( data );

                // data.message

                setErrorMessage("");
                setSuccessMessage(data.message);
                setLicenseKey("");
                parentFetchLicenses();
            } else {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to validate the license.");
            }
        } catch (error) {
            setSuccessMessage("");
            setErrorMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div>
            <label style={{ marginTop: "10px" }}>{t("Add License Key")}</label>
            <br />
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add Email" /> {t('Add')}
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t("Add License Key")}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="addUserWrapper">
                    <div className="mb-3">
                        <label className="form-label">{t("Enter your License Key")}</label>
                        <textarea
                            name="license_key"
                            className="form-control"
                            rows="3"
                            value={licenseKey}
                            onChange={handleLicenseChange}
                        />
                    </div>

                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    {validationKey && <div className="alert alert-info">Validation Key: {validationKey}</div>} {/* Show validation key */}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Close")}
                    </Button>
                    <Button variant="primary" onClick={handleValidateLicense}>
                        {t("Validate License")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddLicenseManager;
