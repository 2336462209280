import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditQuestion.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditQuestion = ({id, parentfetchQuestion}) => {

    // console.log('lesson id is ' + id);
    const { lesson_id } = useParams(); // Assuming `id` is a valid variable available in scope
    const [questions_id] = useState(id); // Use camelCase for naming consistencyws
    const [show, setShow] = useState(false); // Correct syntax for declaring state
    const { t } = useTranslation();



   

    const fetchQuestion= async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${questions_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching Questiondetails.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
            
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["question_en"]: data.question_en,
                ["question_ar"]: data.question_ar,
                ["lesson_id"]: data.lesson_id
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () =>{
        fetchQuestion();
        setShow(true);
    } 

    const [formData, setFormData] = useState({
        question_ar: "",
        question_en: "",
        lesson_id: ""
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdateQuestion = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/questions/${questions_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
            

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Question updated successfully!");
                
                parentfetchQuestion('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update Question. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" />{t('Edit')}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Edit Question')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Question (English)')}</label>
                                <input type="text" name="question_en" className="form-control" onChange={handleChange} value={formData.question_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Question (Arabic)')}</label>
                                <input type="text" name="question_ar" className="form-control" onChange={handleChange} value={formData.question_ar} />
                            </div>
                        </div>
                    </div>
                
                      

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                    </Button>

                    <Button variant="primary" onClick={handleUpdateQuestion}>
                    {t('Update Question')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditQuestion;