import React, { useState } from 'react';
import { api_url } from '../../constant/constant';
import './Updategender.css';

function Updategender() {
  const [selectedGender, setSelectedGender] = useState(null); // Track selected gender
  const [error, setError] = useState(null); // Track errors
  const [loading, setLoading] = useState(false); // Track loading state
  const maleAvatar = '/images/image.png';
  const femaleAvatar = '/images/ImportedPhoto.759012674.455148.jpeg';
  const user_id =localStorage.user_id;
  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
    setError(null); // Clear any previous error
  };

  const handleSubmit = async () => {
    if (!selectedGender) {
      setError("Please select a gender");
      return;
    }

    setLoading(true); // Start loading
    try {
      // Send the selected gender to the backend API
      const response = await fetch(api_url + "/update-gender", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`, // Assuming the token is stored in localStorage
        },
        body: JSON.stringify({ gender: selectedGender,user_id:user_id })
      });
      
      if (!response.ok) {
    
        const errorData = await response.json();
        setError(errorData.message || 'Error updating gender');
        setLoading(false);
        return;
      }
    
      const data = await response.json();
      console.log(data);
  
      // Check if the response is successful and handle the message
      if (data.status === "success") {
        setError(''); // Clear any previous error
        window.location = '/login'; // Redirect to the login page or any other route
      } else {
        setError('Gender update failed. Please try again.');
      }
  
    } catch (err) {
      setError('An error occurred while submitting your gender.');
    } finally {
      setLoading(false); // Ensure loading state is always reset
    }
  };
  return (

    <div className="loginLogo mt-5" align="center">
            <img src="/images/logo.png" alt="Cyber Sense" />
            <div className="card mt-5 pb-5" style={{  width: '60%', borderRadius: '30px' }}>
            <div className="card-body">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '60vh', // Full viewport height
                textAlign: 'center'
            }}>
            <h2 style={{ marginBottom: '40px' }}>Select Your Gender</h2>

                {error && <p style={{ color: "black" }}>{error}</p>}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '20px' }}>
                {/* Male Avatar */}
                <div style={{ textAlign: 'center' }}>
                        <img
                        src={maleAvatar}
                        alt="Male"
                        onClick={() => handleGenderSelect("male")}
                        style={{
                            width: "150px",
                            height: "150px",
                            border: selectedGender === "male" ? "4px solid black" : "2px solid gray",
                            borderRadius: "50%",
                            cursor: "pointer",
                            transition: "0.3s ease",
                        }}
                    />
                    <p>Male</p> {/* Name for Male */}
                </div>

        {/* Female Avatar */}
        <div style={{ textAlign: 'center' }}>
            <img
            src={femaleAvatar}
            alt="Female"
            onClick={() => handleGenderSelect("female")}
            style={{
                width: "150px",
                height: "150px",
                border: selectedGender === "female" ? "4px solid black" : "2px solid gray",
                borderRadius: "50%",
                cursor: "pointer",
                transition: "0.3s ease",
            }}
            />
            <p>Female</p> {/* Name for Female */}
        </div>
        </div>

        <button 
        className="btn btn-dark p-2"
        onClick={handleSubmit}
        disabled={loading || !selectedGender}
        style={{
            width: '30%', // Increases the width of the button to fill its container
            backgroundColor: 'black', // Ensures the button background is black
            borderColor: 'black', // Sets the border color to black
        }}
        >
        {loading ? 'Submitting...' : 'Submit'}
        </button>

      </div>
      </div>
      </div>
      </div>
      
  );
}

export default Updategender;
