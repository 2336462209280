import React, { useState,useEffect } from "react";
import {  NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import "./AddLcUserModal.css";
import { useTranslation } from 'react-i18next';

const AddLcUserModal = ({LcUserModal_id, parentfetchLcUserModal}) =>  {
  // const {question_id} = useParams(); // Get questions_id from the URL
const { t } = useTranslation();

    
    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    user_ids: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false); // Corrected: Set as boolean `false`
  };

  const handleShow = () => setShow(true);

  const handleChange = (event) => {
    const { options } = event.target;
    const selectedValues = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
  
    setFormData({
      ...formData,
      user_ids: selectedValues, // Update with selected values
    });
  };
  const fetchLcUserModal = async (url) => {
    console.log(url);
    let page = 1; 
    if (url) {
      const temp = url.split('?page=');
      page = temp[1] || 1; // Fallback to 1 if page isn't found
    }
    
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/admin/main/users?page=${page}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      setUsers(result.data);
      setLinks(result.links);  // Links for pagination
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
      useEffect(() => {
        fetchLcUserModal('');
      }, []);

  const handleAddLcUserModal = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/learning-campaigns/${LcUserModal_id}/attach-users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage("Selected users added successfully!");
        setFormData({
            user_ids: "",
        });
        parentfetchLcUserModal("");
      } else {
        setSuccessMessage("");
        setErrorMessage("Failed to added selected users. Please try again.");
      }
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <label>{t('Add users to Learning Campaign')} </label>
      <br />
      <NavLink className="btnWhiteGradient" onClick={handleShow}>
        <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')} 
      </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add Users to Learning campaign')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <div className="mb-3">
        <label className="form-label">{t('Select User')}</label>
        <select
        name="user_ids"
        className="form-control"
        onChange={handleChange}
        value={formData.user_ids} // Ensure `formData.user_ids` is an array
        multiple // Enables multi-select
        >
        <option value="" disabled>
            {t('Select users')} {/* Adjust placeholder text */}
        </option>
        {data.map((user) => (
            <option key={user.id} value={user.id}>
            {user.username} ({user.email}) {/* Adjust based on actual field */}
            </option>
            ))}
            </select>

        </div>
         

          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('Close')} 
          </Button>
          <Button variant="primary" onClick={handleAddLcUserModal}>
          {t('Add User ')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddLcUserModal;
