import './App.css';
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Users from './Pages/Users/Users';
import AwarenessManager from './Pages/AwarenessManager/AwarenessManager';
import NotFound from './Pages/NotFound/NotFound';
import Root from './Pages/Root/Root';
import Department from './Pages/Department/Department';
import Email from './Pages/EmailServer/Email';
import { useEffect, useState } from 'react';
import Lessons from './Pages/Lessons/Lessons';
import Questions from './Pages/Questions/Questions';
import Answers from './Pages/Answers/Answers';
import Videos from './Pages/Videos/Videos';
import LessonDetail from './Pages/LessonDetail/LessonDetail';
import License from './Pages/LicenseManager/License';
import { useTranslation } from 'react-i18next';
import Police from './Pages/Polices/Police';
import Surveys from './Pages/Surveys/Surveys';
import SurveyDetail from './Pages/SurveyDetails/SurveyDetail';
import LDAPServer from './Pages/LDAPServer/LDAPServer';
import EmailTemplates from './Pages/EmailTemplates/EmailTemplates';
import AddEmailTemplate from './Components/AddEmailTemplates/AddEmailTemplate';
import EditEmailTemplate from './Components/EditEmailTemplate/EditEmailTemplate';
import LearningManager from './Pages/LearningManager/LearningManager';
import PreviewEmailTemplate from './Components/PreviewEmailTemplate/PreviewEmailTemplate';
import Updategender from './Pages/UpdateGender/Updategender';
import Assessment from './Pages/Assessment/Assessment';
import Infologs from'./Pages/Info-logs/Infologs';
import Errorlogs from'./Pages/Error-logs/Errorlogs';
import UpdatePassword from './Pages/UpdatePassword/UpdatePassword';
import UserDetail from './Pages/UserDetail/UserDetail';
import LDAPServerDetail from './Pages/LDAPServerModelDetails/LDAPServerDetail';
import LearningCompaignDetail from './Pages/LearningCompaignDetail/LearningCompaignDetail';
import Company from './Pages/SystemSettings/Company';
import PhishingCompaign from './Pages/PhishingCompaignManager/PhishingCompaign';
import PhishingCompaignDetail from'./Pages/PhishingCompaignDetail/PhishingCompaignDetail';
const checkAuth = () => {
	const isAuthenticated = localStorage.getItem('userToken');
	if (!isAuthenticated) {
		return redirect('/login');
	}
	return null;
};

// Define Routes
const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <NotFound />,
		children: [
			{ index: true, element: <Dashboard />, loader: checkAuth },
			{ path: '/login', element: <Login /> },
			{ path: '/dashboard', element: <Dashboard />, loader: checkAuth },
			{ path:  '/Updategender', element:<Updategender />},
			{ path:  '/Assessment', element:<Assessment />},
			{ path: '/users', element: <Users />, loader: checkAuth },
			{ path: '/lessons', element: <Lessons />, loader: checkAuth },
			{ path: '/awareness-manager', element: <AwarenessManager />, loader: checkAuth },
			{ path: '/departments', element: <Department />, loader: checkAuth },
			{ path: '/emailserver', element: <Email />, loader: checkAuth },
			{ path: '/lessons/:lesson_id/questions', element: <Questions />, loader: checkAuth },
			{ path: '/questions/:questions_id/answers', element: <Answers />, loader: checkAuth },
			{ path: '/lessons/:lesson_id/videos', element: <Videos />, loader: checkAuth },
			{ path: '/lessons/:lesson_id/detail', element: <LessonDetail />, loader: checkAuth },
			{ path: '/licensemanager', element: <License />, loader: checkAuth },
			{ path: '/policies', element: <Police />, loader: checkAuth },
			{ path: '/surveys', element: <Surveys />, loader: checkAuth },
			{ path: '/surveys/:survey_id/detail', element: <SurveyDetail />, loader: checkAuth },
			{ path: '/ldapserver', element: <LDAPServer />, loader: checkAuth },
			{ path: '/learningcompaignmanager', element: <LearningManager />, loader: checkAuth },
			{ path: '/emailtemplates', element: <EmailTemplates />, loader: checkAuth },
			{ path: '/addemailtemplate', element: <AddEmailTemplate />, loader: checkAuth },
			{ path: '/editemailtemplate/:id', element: <EditEmailTemplate />, loader: checkAuth },
			{ path: '/previewemailtemplate/:id', element: <PreviewEmailTemplate />, loader: checkAuth },
			{path : '/infolog',element:<Infologs />},
			{path : '/errorlog',element:<Errorlogs />},
			{path: '/updatepassword',element:<UpdatePassword />},
			{path:'/learningcompainmanager/:id/LearningCompainDetail', element:<LearningCompaignDetail />},
			{path:'/UserManagement/:id/UserDetail',element:<UserDetail />},
			{ path: '/ldapservermodel/:id/LDAPServerDetail', element: <LDAPServerDetail /> },
			{path:'/learningcompaignmanager/:id/LearningCompaignDetail', element:<LearningCompaignDetail />},
			{path:'/UserManagement/:id/UserDetail',element:<UserDetail />},
			{path:'/company',element:<Company />},
			 {path:"/PhishingCompaign", element:<PhishingCompaign />}, 
			 {path:'/phishinhcompaigndetail/:id/phishinhcompaigndetail',element:<PhishingCompaignDetail />}


		],
	},
]);

function App() {

	const [userLoggeIn, setUserLoggedIn] = useState(false);
	const { i18n } = useTranslation();

	useEffect(() => {
		console.log(localStorage.getItem("userToken"));
		if (localStorage.getItem("userToken") !== null) {
			setUserLoggedIn(true);
		}

		const lng = localStorage.getItem("i18nextLng") || "en";
		i18n.changeLanguage(lng);
		document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
		document.getElementsByTagName("body")[0].setAttribute("dir", lng === "ar" ? "rtl" : "ltr");

		const rtlStylesheetId = "bootstrap-rtl";
		if (lng === "ar") {
			// Add RTL Bootstrap CSS
			const link = document.createElement("link");
			link.id = rtlStylesheetId;
			link.rel = "stylesheet";
			link.href = "/styles/bootstrap.rtl.min.css";
			document.head.appendChild(link);
		}
		else {
			// Remove RTL Bootstrap CSS
			const link = document.getElementById(rtlStylesheetId);
			if (link) {
				document.head.removeChild(link);
			}
		}

	}, []);

	return (
		<div className={`app ${userLoggeIn ? "loggedin" : ""}`}>
			<RouterProvider router={router} />
		</div>
	);

	/*
	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/dashboard" element={<Dashboard />} />
			</Routes>
		</div>
	  ); */
}

export default App;
