import './Dashboard.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useTranslation } from 'react-i18next';
import './Dashboard.css';
import React, { useState, useEffect } from 'react';
import { api_url } from '../../constant/constant';
const Dashboard = () => {
  const { t } = useTranslation();

  const [campaignData, setCampaignData] = useState({
    planned: 0,
    completed: 0,
    active: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const colors = ['#95A4FC', '#BAEDBD', '#1C1C1C', '#B1E3FF', '#A8C5DA', '#A1E3CB'];

  const data = [
    { name: 'Linux', uv: 1800 },
    { name: 'Mac', uv: 3000 },
    { name: 'iOS', uv: 2000 },
    { name: 'Android', uv: 2780 },
    { name: 'Other', uv: 900 },
    { name: 'Other', uv: 2000 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('userToken');
        const response = await fetch(`${api_url}/dashboard`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,// Replace with your actual token
            'Content-Type': 'application/json',
          },
        });

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          const text = await response.text();
          console.error('Non-JSON Response:', text);
          throw new Error(`Invalid API response: ${text}`);
        }

        const result = await response.json();
        if (result.success && result.data) {
          setCampaignData({
            planned: result.data.planned_campaigns?.count || 0,
            completed: result.data.completed_campaigns?.count || 0,
            active: result.data.active_campaigns?.count || 0,
          });
        } else {
          throw new Error(result.message || 'Failed to fetch campaign data.');
        }
      } catch (err) {
        console.error('Fetch error:', err);
        setError(err.message || 'An error occurred while fetching the data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard-container p-4">
      {/* Heading */}
      <h1 className="textAwareness mb-4">{t('Awareness Manager')}</h1>
          {/* Bar Graph */}
      <div className="color">
        <div className="container mt-4">
          {/* Dropdown and Export Section */}
          <div className="row g-3 align-items-center">
            {/* First Dropdown */}
            <div className="col-md-4">
              <div className="dropdown-item">
                <h5 className="dropdown-item mb-2">{t('Select Departments')}</h5>
                <select className="form-select" aria-label="Select Department">
                  <option defaultValue>{t('Select Departmen')}</option>
                  <option value="Computer Department">{t('Computer Department')}</option>
                  <option value="Bio Department">{t('Bio Department')}</option>
                  <option value="Account Department">{t('Account Department')}</option>
                </select>
              </div>
            </div>

            {/* Second Dropdown */}
            <div className="col-md-4">
              <div className="dropdown-item">
                <h5 className="dropdown-item mb-2">{t('Select Campaign')}</h5>
                <select className="form-select" aria-label="Select Campaign">
                  <option defaultValue>{t('Select Campaign')}</option>
                  <option value="Computer Department">{t('Computer Department')}</option>
                  <option value="Bio Department">{t('Bio Department')}</option>
                  <option value="Account Department">{t('Account Department')}</option>
                </select>
              </div>
            </div>

            {/* Export Analytics Button */}
            <div className="col-md-4 d-flex align-items-center">
              <button className="btn custom-btn d-flex align-items-center me-8">
                <i className="bi bi-upload me-2"></i>
                {t('Export Analytics')}
              </button>
            </div>

          </div>

          {/* Advanced Search Filters */}
          <div className="row">
            <div className="Advanced" style={{ marginTop: '15px' }}>
              <i className="bi bi-gear me-2"></i>
              {t('Advanced Search Filters')}
            </div>
          </div>
        </div>
      </div>



      <div className="color section py-4 training-section" style={{ marginTop: '25px', background: '#EFF4F9' }}>
        <p className="Training">{t('Training Campaigns')}</p>
        <div className="d-flex justify-content-between">
        <div className="d-inline-flex align-items-center" style={{ marginRight: '20px' }}>
        <div className="button">{campaignData.planned}</div> {/* Show Planned Campaigns */}
        <div>
          <p className="textComplete">{t('Planned campaigns')}</p>
          <p className="text1">{t('Training campaigns that users have completed')}</p>
        </div>
      </div>

                <div className="d-inline-flex align-items-center" style={{ marginRight: '20px' }}>
        <div className="button">{campaignData.completed}</div> {/* Show Completed Campaigns */}
        <div>
          <p className="textComplete">{t('Completed campaigns')}</p>
          <p className="text1">{t('Active training campaigns that are already running')}</p>
        </div>
      </div>

                <div className="d-inline-flex align-items-center">
        <div className="button">{campaignData.active}</div> {/* Show Active Campaigns */}
        <div>
          <p className="textComplete">{t('Active campaigns')}</p>
          <p className="text1">{t('Planned campaigns which will be starting')}</p>
        </div>
      </div>
        </div>
      </div>







      <div className="color section" style={{ marginTop: '25px', background: '#EFF4F9' }}>
        <h1 className="Training Campaign">{t('Phishing Campaigns')}</h1>
        <div className="row" style={{ marginTop: '50px' }}>
          <div className="col-12 col-md-4">
            <p className="textComplete">{t('Completed Phishing Campaigns')}</p>
            <p className="text1">{t('Phishing campaigns that are completed')}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="textComplete">{t('Active Phishing Campaigns')}</p>
            <p className="text1">{t('Active phishing campaigns that are already running')}</p>
          </div>
          <div className="col-12 col-md-4">
            <p className="textComplete">{t('Planned Phishing Campaigns')}</p>
            <p className="text1">{t('Planned phishing campaigns which will start soon')}</p>
          </div>
        </div>
      </div>


      {/* Campaign Engagement Section */}
      <div className="color section" style={{ marginTop: '25px', background: '#EFF4F9', marginBottom: '40px' }}>
        <h1 className="Campaign Engagement">{t('Campaign Engagement')}</h1>
        <div style={{ display: 'flex', alignItems: 'flex-start', gap: '40px' }}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Bar dataKey="uv" fill="#8884d8" radius={9.932}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
          <div>
            <ul style={{ margin: 0, padding: 0, marginTop: '120px' }}>
              <li>{t('Community Management 15%')}</li>
              <li>{t('Construction Delivery 41%')}</li>
              <li>{t('Safety Security 11%')}</li>
              <li>{t('Other')}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
