import { useEffect, useState } from 'react';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import './Head.css';

const Head = () => {

    const { t } = useTranslation();

    const [firstName, setFirstName] = useState('true');

    useEffect(()=>{
        var jsonData = localStorage.getItem("userData");
        var userData = JSON.parse(jsonData);
        setFirstName( userData?.user?.firstname );
    }, []);

    const handleLogout = () => {
        
        localStorage.removeItem("userToken");
        localStorage.removeItem("userData");
        window.location = "/login";
    }

    return (
        <div className="p-4 siteHeadWrapper">

            <div className="rightHeadWrapper">
                
            </div>

            <div className="leftHeadWrapper">
                <div>
                    <LanguageSwitcher />
                </div>
                <div className="helpWrapper">
                    <i className="bi bi-question-circle"></i> {t('Help')}
                </div>
                
                <div className="profileWrapper d-flex align-items-center">
                    <span className="profileLogo">
                        <img src="/images/profile.png" width="32" alt="{firstName}" />
                    </span>

                    <span className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#profile" id="submenu" role="button">
                            {firstName}
                        </a>
                        <ul className="dropdown-menu submenu" aria-labelledby="submenu" style={{marginRight:"-20px"}}>
                            <li><a className="dropdown-item submenu-item" href="#profile">{t('Profile')}</a></li>
                            <li><a className="dropdown-item submenu-item" href="#settings">{t('Settings')}</a></li>
                            <li>
                                <button 
                                    className="dropdown-item submenu-item" 
                                    onClick={handleLogout}
                                    >{t('Logout')}</button>
                            </li>
                        </ul>
                    </span> 
                </div>
            </div>
            
        </div>
    );
}

export default Head;