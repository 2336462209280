import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddSurvey.css';
import { useTranslation } from 'react-i18next';

const AddSurvey = ({parentfetchSurveys}) => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        title_en: "",
        title_ar: "",
        description_en: "",
        description_ar: "",
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleAddSurvey = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/surveys", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Survey added successfully!");
                setFormData({
                    title_en: "",
                    title_ar: "",
                    description_en: "",
                    description_ar: "",
                });
                parentfetchSurveys('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to add Survey. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <label>{t('Add New Survey')}</label>
            <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')} 
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Add New Survey')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Title (English)')}</label>
                                <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Title (Arabic)')}</label>
                                <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Description (English)')}</label>
                                <textarea name="description_en" className="form-control" onChange={handleChange}>{formData.description_en}</textarea>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Description (Arabic)')}</label>
                                <textarea name="description_ar" className="form-control" onChange={handleChange}>{formData.description_ar}</textarea>
                            </div>
                        </div>
                    </div>
                    

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                    </Button>

                    <Button variant="primary" onClick={handleAddSurvey}>
                    {t('Add Survey')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default AddSurvey;