import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import './AddVideo.css';
import { useTranslation } from 'react-i18next';

const AddVideo = ({ parentfetchVideo}) => {

	const { lesson_id } = useParams();
  const { t } = useTranslation();



    const [show, setShow] = useState(false);

    const handleClose = () => {
          setSuccessMessage('');
          setErrorMessage('');
          setShow(false);
    };

    const handleShow = () => setShow(true);
  
    const [formData, setFormData] = useState({
		  language: "en",
        video: null
    });
  
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
    };

	const handleFileChange = (e) => {
		setFormData({
		  ...formData,
		  video: e.target.files[0],
		});
	};
  
    const handleAddVideo = async (e) => {
    	e.preventDefault();
      console.log("language:",formData.language);
		const data = new FormData();
    	data.append("language", formData.language);
    	data.append("video", formData.video);
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/lessons/${lesson_id}/videos`, {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
          body: data
        });

        if (response.ok) {
          setErrorMessage("");
          setSuccessMessage("Video added successfully!");
          setFormData({
            language: "",
            video: ""
          });
          parentfetchVideo('');
        
        } else {
          setSuccessMessage("");
          setErrorMessage("Failed to add video. Please try again.");
        }
      } catch (error) {
        setSuccessMessage("");
        setErrorMessage(error.message);
      }
	};


  return (
    <div>
      <label>{t('Add New Video')}</label>
      <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <i class="bi bi-camera-video"></i> {t('Add')}
            </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add New Video')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
			<div className="mb-3">
            	<label className="form-label">{t('Language')}</label>
				<select className="form-select" aria-label="Language" value={formData.language}  name="language" onChange={handleChange} required >
					<option value="en">{t('English')}</option>
					<option value="ar">{t('Arabic')}</option>
				</select>
          	</div>

          <div className="mb-3">
            <label className="form-label">{t('Video')}</label>
            <input
              type="file"
			        className='form-control'
              accept="video/*"
              onChange={handleFileChange}
              required
            />
          </div>

          	{successMessage && <div className="alert alert-success">{successMessage}</div>}
          	{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('Close')}
          </Button>
          <Button variant="primary" onClick={handleAddVideo}>
          {t('Add Video')} 
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddVideo;
