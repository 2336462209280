import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

import './AddSurveyQuestion.css';

const AddSurveyQuestion = ({parentfetchQuestion}) => {
  const { survey_id } = useParams(); // Get lessonId from the URL
  
  const { t } = useTranslation();

  const [question_types, setQuestionTypes] = useState([]);

const fetchQuestionTypes = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(api_url + '/question-types', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result); // Verify the API response
    setQuestionTypes(result.data || result); // Adjust based on API structure
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
  fetchQuestionTypes();
}, []);
  
      const [show, setShow] = useState(false);
      const handleClose = () => {
          setSuccessMessage('');
          setErrorMessage('');
          setShow(false);
      };
      const handleShow = () => setShow(true);
  
      const [formData, setFormData] = useState({
        question_text_en: "",
        question_text_ar: "",
        question_type: ""
        });
  
      const [successMessage, setSuccessMessage] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
      };
  
    const handleAddSurveyQuestion = async (e) => {
    e.preventDefault();

    console.log(formData);

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/surveys/${survey_id}/questions`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`, 
          },
          body: JSON.stringify(formData),
          redirect: "follow"
      });

      if (response.ok) 
      {
          setErrorMessage("");
          setSuccessMessage(" Add survey question added successfully!");
          setFormData({
            question_text_en: "",
            question_text_ar: "",
            question_type: "",
          });
          parentfetchQuestion('');
      } 
      else 
      {
          setSuccessMessage("");
          setErrorMessage("Failed to add survey question. Please try again.");
      }
  }
  catch (error) {
      setSuccessMessage("");
      setErrorMessage(error);
  }
}


  return (
    <div>
      {/* <h1> {lessonid}</h1> */}
      <label>{t('Add New Survey Question')}</label>
      <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')}
            </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add New Survey Question')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        {/* <div className="mb-3">
            <label className="form-label">Lesson ID</label>
            <input
              type="text"
              name="lesson_id"
              className="form-control"
              onChange={handleChange}
              value={formData.lesson_id}
            />
          </div> */}
          <div className="mb-3">
            <label className="form-label">{t('Question (English)')}</label>
            <input
              type="text"
              name="question_text_en"
              className="form-control"
              onChange={handleChange}
              value={formData.question_text_en}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Question (Arabic)')} </label>
            <input
              type="text"
              name="question_text_ar"
              className="form-control"
              onChange={handleChange}
              value={formData.question_text_ar}
            />
          </div> 
                            <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">{t('Question type')}</label>
                                <select name="question_type" className="form-select" onChange={handleChange} required>
                                    <option></option>
                                    {question_types && question_types.map((question, index) => (
                                        <option value={question.value}>{question.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>


         

          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('Close')}
          </Button>
          <Button variant="primary" onClick={handleAddSurveyQuestion}>
          {t('Add Survey Question')}
         
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddSurveyQuestion;
