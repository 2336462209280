import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

const EditLearningManager = ({id, parentfetchLearningManager}) => {

    const { t } = useTranslation();
    const [Learning_types, setLearningTypes] = useState([]);

    const fetchLearningManagerTypes = async () => {
      try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(api_url + '/learning-campaigns/types', {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, 
            },
          });
                  
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
        
                    const result = await response.json();
                    setLearningTypes(result);
                    
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

    const [show, setShow] = useState(false);
   
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [userId] = useState(id);
    const [formData, setFormData] = useState({
        type: "",
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        start_date: "",
        start_time: "",
        end_date: "",
        video_seek: ""
    });
 

     const fetchLearningManager = async() => {
  
          try 
        {
              const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/learning-campaigns/" + userId, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}`, 
                }
            });
      
            if (!response.ok) 
            {
                setErrorMessage("Error fetching user details.");
                  setSuccessMessage("");
            }
      
            const data = await response.json();
            setErrorMessage("");
              setSuccessMessage("");
            
              setFormData({
                  ...formData,
                  ["type"]: data.type,
                  ["title_ar"]: data.title_ar,
                  ["title_en"]: data.title_en,
                  ["description_ar"]: data.description_ar,
                  ["description_en"]: data.description_en,
                  ["start_date"]: data.start_date,
                  ["start_time"]: data.start_time,
                  ["end_date"]: data.end_date,
                  ["video_seek"]: data.video_seek,
              });
  
        } 
        catch (err) 
        {
            setErrorMessage(err.message);
              setSuccessMessage("");
        }
      }
      useEffect(() => {
        fetchLearningManagerTypes();
        fetchLearningManager();
    }, []);
  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
      };

   const handleEditLearningManager = async (e) => {
           e.preventDefault();
   
           console.log(formData);
   
           try {
               const token = localStorage.getItem("userToken");
               const response = await fetch(api_url + "/learning-campaigns/" + userId, {
                   method: "PUT",
                   headers: {
                       "Content-Type": "application/json",
                       "Authorization": `Bearer ${token}`, 
                   },
                   body: JSON.stringify(formData),
                   redirect: "follow"
               });
   
               if (response.ok) 
               {
                   setErrorMessage("");
                   setSuccessMessage("Learning manager updated successfully!");
                   parentfetchLearningManager('');
               } 
               else 
               {
                   setSuccessMessage("");
                   setErrorMessage("Failed to update Learning manager. Please try again.");
               }
           }
           catch (error) {
               setSuccessMessage("");
               setErrorMessage(error);
           }
       }

    return (
        <div className="py-4">

<div className="row">
                    <div className="col-md-6">
                    <div className="col-md-12">
                    <div className="mb-3">
                                <label className="form-label">{t('Type')}</label>
                                <select name="type" className="form-select" onChange={handleChange} required  value={formData.type}>
                                    <option></option>
                                    {Learning_types && Learning_types.map((Learning, index) => (
                                        <option value={Learning}>{Learning}</option>
                                    ))}
                                </select>
                            </div>
                            </div>
                            </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Title ar')}</label>
                                <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Title en')}</label>
                                <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Description ar ')}</label>
                                <input type="text" name="description_ar" className="form-control" onChange={handleChange} value={formData.description_ar} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Description en')}</label>
                                <input type="text" name="description_en" className="form-control" onChange={handleChange} value={formData.description_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Start date')}</label>
                                <input type="date" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Start time')}</label>
                                <input type="time" name="start_time" className="form-control" onChange={handleChange} value={formData.start_time} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('End date')}</label>
                                <input type="date" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Video seek')}</label>
                                <input type="text" name="video_seek" className="form-control" onChange={handleChange} value={formData.video_seek} />
                            </div>
                        </div>
                    
                        <div className="col-md-6">
                            <div className="mb-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {/* <div className="mb-3">
                                <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            {/* <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

            <Button variant="primary" onClick={handleEditLearningManager}>
            {t('Update Leraning Manager')}
            </Button>
        </div>
      );

}

export default EditLearningManager;