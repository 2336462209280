import React, { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";

const Company = () => {
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState(false);
  const [captchaSettings, setCaptchaSettings] = useState({});
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

  // Fetch System Config (for 2FA)
  const fetchSystemConfig = async () => {
    try {
      const response = await fetch(`${api_url}/customer-settings/system-config`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      const res = await response.json();
      if (response.ok) {
        if (res.success == true && res.data.two_factor_enabled === 1) {
          setTwoFactorEnabled(true);
        }
      } else {
        console.error("Failed to fetch system config:", res);
      }
    } catch (error) {
      console.error("Error while fetching system config:", error);
    }
  };

  // Fetch Captcha Settings
  const fetchCaptchaSettings = async () => {
    try {
      const response = await fetch(`${api_url}/captcha-settings`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      });

      const res = await response.json();
      if (response.ok) {
        const { enabled_login, login_attempts, code_valid_duration, enabled_forgot_password } = res;
        setCaptcha(enabled_login === 1);
        setCaptchaSettings({
          enabled_login,
          login_attempts,
          code_valid_duration,
          enabled_forgot_password,
        });
      } else {
        console.error("Failed to fetch Captcha settings:", res);
      }
    } catch (error) {
      console.error("Error while fetching Captcha settings:", error);
    }
  };

  // Toggle Captcha
  const toggleCaptcha = async () => {
    let status;
    if(captcha == true){
      setCaptcha(false);
      status = false;
    }else{
      setCaptcha(true);
      status = true;
    }
    // const newState = !captcha;
    const { enabled_login, login_attempts, code_valid_duration, enabled_forgot_password } = captchaSettings;
    try {
      const response = await fetch(`${api_url}/captcha-settings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({
          enabled_login: status,
          login_attempts,
          code_valid_duration,
          enabled_forgot_password,
        }),
      });

      const res = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error while updating 2FA settings:", error);
      // alert("An error occurred while updating 2FA settings. Please try again.");
    }
  };

  // Toggle Two-Factor Authentication (2FA)
  const toggleTwoFactorAuth = async () => {
    let twoFAstatus;
    if(twoFactorEnabled==true){
      setTwoFactorEnabled(false);
      twoFAstatus = false;
    }else{
      setTwoFactorEnabled(true);
      twoFAstatus = true;
    }
    const newState = !twoFactorEnabled;

    try {
      const response = await fetch(`${api_url}/customer-settings/update-two-factor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({
          "two_factor_enabled": twoFAstatus,
          "two_factor_code_length": 6,
          "two_factor_code_expiry": 5,
          "server_id": 1
        }),
      });

      const res = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP Error ${response.status}: ${response.statusText}`);
      }

      // console.log(`2FA ${newState ? "enabled" : "disabled"} successfully:`, res);
      // alert(`Two-factor authentication has been ${newState ? "enabled" : "disabled"}.`);

      setTwoFactorEnabled(newState);
    } catch (error) {
      console.error("Error while updating 2FA settings:", error);
      // alert("An error occurred while updating 2FA settings. Please try again.");
    }
  };

  // Initial Fetch
  useEffect(() => {
    fetchSystemConfig(); // Call this API for initial data for 2FA
    fetchCaptchaSettings(); // Call this API for initial data for Captcha
  }, []);

  return (
    <div className="p-4 h-100">
      <div className="my-4">
        <div className="pageTitle mb-4">{t("Company")}</div>
      </div>
      <div className="container">
        <div className="card mt-5 pb-5 p-4" style={{ width: "100%", maxWidth: "500px" }}>
          {/* Captcha Toggle */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <label className="form-label mb-0" style={{ fontSize: "18px" }}>
              Captcha
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="captchaSwitch"
                checked={captcha}
                onChange={toggleCaptcha}
              />
              <label className="form-check-label" htmlFor="captchaSwitch">
                {captcha ? "Enabled" : "Disabled"}
              </label>
            </div>
          </div>

          {/* 2FA Toggle */}
          <div className="d-flex justify-content-between align-items-center">
            <label className="form-label mb-0" style={{ fontSize: "18px" }}>
              2FA
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="twoFactorSwitch"
                checked={twoFactorEnabled}
                onChange={toggleTwoFactorAuth}
              />
              <label className="form-check-label" htmlFor="twoFactorSwitch">
                {twoFactorEnabled ? "Enabled" : "Disabled"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
