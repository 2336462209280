import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { api_url } from "../../constant/constant";
import "./AddAnswers.css";
import { useTranslation } from 'react-i18next';

const AddAnswers = ({ question_id, parentfetchAnswers }) => {
  // const {question_id} = useParams(); // Get questions_id from the URL
  const { t } = useTranslation();



  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    answer_en: "",
    answer_ar: "",
    is_correct: 0, // Initialize as 0 for checkbox
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false); // Corrected: Set as boolean `false`
  };

  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Convert checkbox to 0 or 1
    });
  };

  const handleAddAnswers = async (e) => {
    e.preventDefault();
    console.log("dummmy answer");
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${api_url}/questions/${question_id}/answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage("Answer added successfully!");
        setFormData({
          answer_ar: "",
          answer_en: "",
          is_correct: 0, // Reset checkbox value
        });
        parentfetchAnswers("");
      } else {
        setSuccessMessage("");
        setErrorMessage("Failed to added Answer. Please try again.");
      }
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      <label>{t('Add New Answer')} </label>
      <br />
      <NavLink className="btnWhiteGradient" onClick={handleShow}>
        <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')}
      </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add New Answer')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">{t('Answer (English)')}</label>
            <input
              type="text"
              name="answer_en"
              className="form-control"
              onChange={handleChange}
              value={formData.answer_en}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Answer (Arabic)')} </label>
            <input
              type="text"
              name="answer_ar"
              className="form-control"
              onChange={handleChange}
              value={formData.answer_ar}
            />
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="is_correct"
              id="flexCheckIndeterminate"
              onChange={handleChange}
              checked={formData.is_correct === 1} // Bind checkbox value
            />
            <label className="form-check-label" htmlFor="flexCheckIndeterminate">
              {t('Correct')}
            </label>
          </div>

          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
          <Button variant="primary" onClick={()=>handleAddAnswers}>
            {t('Add Answer')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddAnswers;
