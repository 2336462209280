import { useLocation, NavLink, json } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './LeftNav.css';

function LeftNav() {
    const location = useLocation();
    const { t } = useTranslation();
    const isActive = (path) => location.pathname === path;
    var userData = localStorage.getItem('userData');
    if (userData) {
        userData = JSON.parse(userData);
    }
    return (
        <div className="col-md-3 pt-4 pb-4 siteLeft">
            <div className="logo ps-3">
                <img src="/images/ByeWind.png" alt="Cyber Sense" />
            </div>

            <div className="sidebarNavWrapper">
                <Container>
                    <Row className='pt-5'>
                        <Col xs={6}>
                            <div className="pageName ps-3">{t('Dashboard')}</div>
                        </Col>
                        <Col xs={6} className='text-end d-lg-none'>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="bi bi-list"></i>
                            </button>
                        </Col>
                    </Row>
                </Container>

                <nav className="navbar navbar-expand-lg p-3">
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="list-unstyled">
                            <li >

                                <li className="first-item py-2">
                                    <NavLink
                                        to="/dashboard"
                                        className={`d-block py-2 px-2 ${isActive("/dashboard") ? "active" : ""}`}
                                    >
                                        <i className="bi bi-pie-chart me-2 text-bluelightsense"></i> {t('Analytics')}
                                    </NavLink>
                                </li>

                            </li>
                            {/* Conditionally render the section based on role_id */}
                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <a
                                        className="d-flex align-items-center py-2 px-2"
                                        data-bs-toggle="collapse"
                                        href="#dashboardcon"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="dashboardcon"
                                    >
                                        <i className="bi bi-grid me-2 text-bluelightsense"></i> {t('Dashboard Configuration')} <i className="ms-auto bi bi-chevron-down"></i>
                                    </a>
                                    <div className="collapse" id="dashboardcon">
                                        <ul className="list-unstyled ps-4">
                                            <li>
                                                <a
                                                    className="d-flex align-items-center py-2 px-2"
                                                    data-bs-toggle="collapse"
                                                    href="#systemsettings"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="systemsettings">
                                                    {t('System Settings')} <i className="ms-auto bi bi-chevron-down"></i>
                                                </a>
                                                <div className="collapse" id="systemsettings">
                                                    <ul className="list-unstyled ps-4">
                                                        <li>
                                                            <NavLink
                                                                to="/companydetail"
                                                                className={`d-block py-2 px-2 ${isActive("/companydetail") ? "active" : ""}`}>
                                                                {t('Company')}
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>


                                            <li>
                                                <a href="#sub1-2" className="d-block py-1 px-2">
                                                    {t('Compliance Manager')}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#sub1-2" className="d-block py-1 px-2">
                                                    {t('PhishScout')}
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#sub1-2" className="d-block py-1 px-2">
                                                    {t('Survey Manager')}
                                                </a>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/emailserver"
                                                    className={`d-block py-2 px-2 ${isActive("/emailserver") ? "active" : ""}`}
                                                >
                                                    {t('Email Server Manager')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/emailtemplates"
                                                    className={`d-block py-2 px-2 ${isActive("/emailtemplates") ? "active" : ""}`}
                                                >
                                                    {t('Email Templates')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/licensemanager"
                                                    className={`d-block py-2 px-2 ${isActive("/licensemanager") ? "active" : ""}`}
                                                >
                                                    {t('License Manager')}
                                                </NavLink>

                                            </li>
                                            {/* <li>
                                        <NavLink
                                            to="/ldapserver"
                                            className={`d-block py-2 px-2 ${isActive("/ldapserver") ? "active" : ""}`}
                                        >
                                            {t('LDAP Server')}
                                            
                                        </NavLink>
                                        
                                    </li> */}


                                        </ul>
                                    </div>
                                </li>
                            )}


                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <a
                                        className="d-flex align-items-center py-2 px-2"
                                        data-bs-toggle="collapse"
                                        href="#usermanagement"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="usermanagement"
                                    >
                                        <i class="bi bi-person me-2 text-bluelightsense"></i>   {t('User Management')} <i className="ms-auto bi bi-chevron-down"></i>
                                    </a>
                                    <div className="collapse" id="usermanagement">
                                        <ul className="list-unstyled ps-4">
                                            <li>
                                                <NavLink
                                                    to="/users"
                                                    className={`d-block py-2 px-2 ${isActive("/users") ? "active" : ""}`}
                                                >
                                                    {t('All Users')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/departments"
                                                    className={`d-block py-2 px-2 ${isActive("/departments") ? "active" : ""}`}
                                                >
                                                    {t('Departments Manager')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <a
                                                    className="d-flex align-items-center py-2 px-2"
                                                    data-bs-toggle="collapse"
                                                    href="#ldap"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="ldap"
                                                >
                                                    {t('LDAP')} <i className="ms-auto bi bi-chevron-down"></i>
                                                </a>
                                                <div className="collapse" id="ldap">
                                                    <ul className="list-unstyled ps-4">
                                                        <li>
                                                            <NavLink
                                                                to="/ldapserver"
                                                                className={`d-block py-2 px-2 ${isActive("/ldapserver") ? "active" : ""}`}
                                                            >
                                                                {t('LDAP Server')}

                                                            </NavLink>

                                                    </li>
                                                    {/* <li>
                                                        <NavLink
                                                            to="/infolog"
                                                            className={`d-block py-2 px-2 ${isActive("/infolog") ? "active" : ""}`}
                                                        >
                                                            {t('Info-logs')}
                                                        </NavLink>
                                                    </li> */}
                                                    {/* <li>
                                                        <NavLink
                                                            to="/errorlog"
                                                            className={`d-block py-2 px-2 ${isActive("/errorlog") ? "active" : ""}`}
                                                        >
                                                            {t('Error-logs')}
                                                        </NavLink>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </li>


                                        </ul>
                                    </div>
                                </li>
                            )}

                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    {/* Conditionally render the "Training Management" section based on role_id */}
                                    <a
                                        className="d-flex align-items-center py-2 px-2"
                                        data-bs-toggle="collapse"
                                        href="#training"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="training"
                                    >
                                        <i className="bi bi-easel me-2 text-bluelightsense"></i> {t('Training Management')} <i className="ms-auto bi bi-chevron-down"></i>
                                    </a>
                                    {/* Collapse content */}
                                    <div className="collapse" id="training">
                                        <ul className="list-unstyled ps-4">
                                            <li>
                                                <NavLink
                                                    to="/lessons"
                                                    className={`d-block py-2 px-2 ${isActive("/lessons") ? "active" : ""}`}
                                                >
                                                    {t('Lessons')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/policies"
                                                    className={`d-block py-2 px-2 ${isActive("/policies") ? "active" : ""}`}
                                                >
                                                    {t('Policies')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink
                                                    to="/surveys"
                                                    className={`d-block py-2 px-2 ${isActive("/surveys") ? "active" : ""}`}
                                                >
                                                    {t('Surveys')}
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            )}
                            {/* Conditionally render the "My Certificate" section based on role_id */}
                            {userData?.role_id === 5 && (
                                <li className="first-item py-2">
                                    <a href="#home" className="d-block py-2 px-2">
                                        <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> {t('My Training')}
                                    </a>
                                </li>
                            )}


                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <NavLink
                                        to="/learningcompaignmanager"
                                        className={`d-block py-2 px-2 ${isActive("/learningcompaignmanager") ? "active" : ""}`}
                                    >
                                        <i className="bi bi-collection me-2 text-bluelightsense"></i> {t('Learning Compaign Manager')}
                                    </NavLink>
                                </li>
                            )}
                            {/* Conditionally render the "Phishing Management" section based on role_id */}
                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    {/* <a href="#home" className="d-block py-2 px-2">
                                        <i className="bi bi-exclamation-triangle me-2 text-bluelightsense"></i> {t('Phishing Management')}
                                    </a> */}
                                      <NavLink
                                        to="/PhishingCompaign"
                                        className={`d-block py-2 px-2 ${isActive("/PhishingCompaign") ? "active" : ""}`}
                                    >
                                        <i className="bi bi-collection me-2 text-bluelightsense"></i> {t('Phishing Management')}
                                    </NavLink>
                                </li>
                            )}
                            {/* Conditionally render the "My Certificate" section based on role_id */}
                            {userData?.role_id === 5 && (
                                <li className="first-item py-2">
                                    <a href="#home" className="d-block py-2 px-2">
                                        <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> {t('My Certificate')}
                                    </a>
                                </li>
                            )}
                            {userData?.role_id !== 5 && (
                                <li className="first-item py-2">
                                    <a href="#home" className="d-block py-2 px-2">
                                        <i className="bi bi-file-earmark-text me-2 text-bluelightsense"></i> {t('Awareness Management')}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default LeftNav;
