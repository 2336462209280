import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

const EditPhishingCompaign = ({id, parentfetchLearningManager}) => {

    const { t } = useTranslation();

 

    const [show, setShow] = useState(false);
   
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [userId] = useState(id);
    const [formData, setFormData] = useState({
        name_ar: "",
        name_en: "",
        start_date: "",
        end_date: "",
        template_id: "",
        save_submitted_data: "",
        enable_attachment: "",
        attachment: "",
        execute_url: ""
    });
 

     const fetchPhishingCompaign = async() => {
  
          try 
        {
              const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/phishing-campaigns/" + userId, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}`, 
                }
            });
      
            if (!response.ok) 
            {
                setErrorMessage("Error fetching user details.");
                  setSuccessMessage("");
            }
      
            const data = await response.json();
            setErrorMessage("");
              setSuccessMessage("");
            
              setFormData({
                  ...formData,
                  ["name_ar"]: data.name_ar,
                  ["name_en"]: data.name_en,
                  ["start_date"]: data.start_date,
                  ["end_date"]: data.end_date,
                  ["template_id"]: data.template_id,
                  ["save_submitted_data"]: data.save_submitted_data,
                  ["enable_attachment"]: data.enable_attachment,
                  ["attachment"]: data.attachment,
                  ["execute_url"]: data.execute_url,
              });
  
        } 
        catch (err) 
        {
            setErrorMessage(err.message);
              setSuccessMessage("");
        }
      }
      useEffect(() => {
        fetchPhishingCompaign();
    }, []);
  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
      };

   const handleEditPhishingCompaign = async (e) => {
           e.preventDefault();
   
           console.log(formData);
   
           try {
               const token = localStorage.getItem("userToken");
               const response = await fetch(api_url + "/phishing-campaigns/" + userId, {
                   method: "PUT",
                   headers: {
                       "Content-Type": "application/json",
                       "Authorization": `Bearer ${token}`, 
                   },
                   body: JSON.stringify(formData),
                   redirect: "follow"
               });
   
               if (response.ok) 
               {
                   setErrorMessage("");
                   setSuccessMessage("Phishing Compaign updated successfully!");
                   fetchPhishingCompaign();
               } 
               else 
               {
                   setSuccessMessage("");
                   setErrorMessage("Failed to update Phishing Compaign. Please try again.");
               }
           }
           catch (error) {
               setSuccessMessage("");
               setErrorMessage(error);
           }
       }

    return (
        <div className="py-4">

                    <div className="row">
                    
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name ar')}</label>
                                <input type="text" name="name_ar" className="form-control" onChange={handleChange} value={formData.name_ar} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Execute url')}</label>
                                <input type="text" name="execute_url" className="form-control" onChange={handleChange} value={formData.execute_url} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name en')}</label>
                                <input type="text" name="name_en" className="form-control" onChange={handleChange} value={formData.name_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Template id ')}</label>
                                <input type="text" name="template_id" className="form-control" onChange={handleChange} value={formData.template_id} />
                            </div>
                        </div>
                       
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Start date')}</label>
                                <input type="datetime-local" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('End Date')}</label>
                                <input type="datetime-local" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Enable Attachment ')}</label>
                                <input type="text" name="enable_attachment" className="form-control" onChange={handleChange} value={formData.enable_attachment} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Attachment')}</label>
                                <input type="text" name="attachment" className="form-control" onChange={handleChange} value={formData.attachment} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Save Submitted Data')}</label>
                                <input type="text" name="save_submitted_data" className="form-control" onChange={handleChange} value={formData.save_submitted_data} />
                            </div>
                        </div>
                    
                        
                    </div>
                

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

            <Button variant="primary" onClick={handleEditPhishingCompaign}>
            {t('Update Phishing Compaign')}
            </Button>
        </div>
      );

}

export default EditPhishingCompaign;