import React, { useState } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";

function UpdatePassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");
      
        // Check if passwords match
        if (password !== confirmPassword) {
          setError("Passwords do not match.");
          return;
        }
      
        setLoading(true);
      
        // Get the user data from localStorage and parse it
        const userData = JSON.parse(localStorage.getItem("userData")); 
        const token = localStorage.getItem("userToken");
      
        if (!userData) {
          setError("User not authenticated.");
          setLoading(false);
          return;
        }
      
        const userId = userData.id; // Fetch the user_id from the parsed userData
      
        try {
          const response = await fetch(`${api_url}/change-password`, {
            method: "POST", 
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`, 
            },
            body: JSON.stringify({
              user_id: userId, 
              password: password,
              password_confirmation: confirmPassword,
            }),
          });
      
          if (!response.ok) {
            const errorData = await response.json();
            setError(errorData.message || "Failed to update password.");
            throw new Error(errorData.message);
          }
      
          const data = await response.json();
      
          if (data.status === "success") {
            setSuccess(data.message || "Password updated successfully.");
      
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/login"; 
            }, 2000); 
          } else {
            setError("Something went wrong. Please try again.");
          }
      
        } catch (err) {
          console.error(err.message);
          setError("An error occurred. Please try again.");
        } finally {
          setLoading(false);
        }
      };
      
      

    return (
        <div className="container" style={{height: '100vh'}}>
            <div className="row justify-content-center">
                <div className="col-md-5 mb-5" >
                    <div className="loginLogo mt-5" align="center">
                        <img src="/images/logo.png" alt="Cyber Sense" />
                    </div>
                    <div className="card mt-5 pb-5" style={{ borderRadius: "30px" }}>
                        <div className="card-body">
                            <h2 className="m-5 txtLoginAccount" align="center">{t('Update Password')}</h2>

                            {error && <div className="alert alert-danger">{error}</div>}
                            {success && <div className="alert alert-success">{success}</div>}

                            <form onSubmit={handleSubmit} className="px-5">
                                <div className="form-group mb-3">
                                    <label htmlFor="password">{t("New Password")}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="confirmPassword">{t("Confirm Password")}</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-dark w-100 p-2"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        t("Update Password")
                                    )}
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdatePassword;
