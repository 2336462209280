import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './Addemail.css';
import { useTranslation } from 'react-i18next';

const AddEmail = ({onfetchEmail}) => {
    const { t } = useTranslation();



    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        name: "",
        username: "",
        password: "",
        host: "",
        port: "",
        encryption: "",
        
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleAddEmail = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/email-servers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Email server added successfully!");
                setFormData({
                    name: "",
                    username: "",
                    password: "",
                    host: "",
                    port: "",
                    encryption: "",
                    
                
                });

                onfetchEmail('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to add Email. Please try again.");

            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <label style={{marginTop:'10px'}}>{t('Add New Email Server')}</label>
            <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add Email" /> {t('Add')}
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle" >{t('Add New Email Servers')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name')}</label>
                                <input type="text" name="name" className="form-control" onChange={handleChange} value={formData.name} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Username')}</label>
                                <input type="email" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Password')}</label>
                                <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Host')}</label>
                                <input type="text" name="host" className="form-control" onChange={handleChange} value={formData.host} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Port')}</label>
                                <input type="text" name="port" className="form-control" onChange={handleChange} value={formData.port} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Encryption')}</label>
                                <input type="encryption" name="encryption" className="form-control" onChange={handleChange} value={formData.encryption} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                {/* <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select> */}
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                        {/* </div> */}
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')} 
                    </Button>

                    <Button variant="primary" onClick={handleAddEmail}>
                    {t('Add Email Server')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default AddEmail;