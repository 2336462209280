import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { api_url } from '../../constant/constant'; // Make sure this is the correct path to your api_url constant

const Assessment = () => {
    const { t } = useTranslation();

    const [questions, setQuestions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [error, setError] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [result, setResult] = useState(null); // To store result from API
    const [isLoading, setIsLoading] = useState(false); // To show loading state while submitting

    useEffect(() => {
        const questionArr = localStorage.getItem("questions");
        if (questionArr) {
            try {
                const parsedQuestions = JSON.parse(questionArr).map(question => {
                    try {
                        question.options = JSON.parse(question.options);
                    } catch (e) {
                        question.options = [];
                    }
                    if (!Array.isArray(question.options)) {
                        question.options = [];
                    }
                    return question;
                });
                setQuestions(parsedQuestions);
            } catch (e) {
                console.error('Error parsing questions from localStorage', e);
            }
        } else {
            console.error('No questions found in localStorage');
        }
    }, []);

    const handleNextQuestion = () => {
        console.log("handle NExt");
        if (!selectedOption) {
            setError(t('Please select an option.'));
            return;
        }

        setError('');

        const newAnswer = {
            question_id: questions[currentQuestionIndex].question_id,
            answer: selectedOption,
        };

        setAnswers(prevAnswers => {
            const updatedAnswers = [...prevAnswers, newAnswer];
            return updatedAnswers;
        });

        if (currentQuestionIndex < questions.length - 1) {
            setSelectedOption('');
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsCompleted(true);
            // Submit the answers after the last question
            submitAssessment();
        }
    };

    const submitAssessment = async () => {
        setIsLoading(true);
       const answersFromState = answers;
        const token = localStorage.getItem('auth_token'); 
        console.log("answersFromStorage",answersFromState);
        try {
            const response = await fetch(api_url + "/submit-assessment", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({ answers: answersFromState }),
            });

            if (response.ok) {
                const data = await response.json();
                const { message, score, awareness_score_percentage } = data;

                setResult({
                    message: message || 'Assessment submitted successfully',
                    score: score || '0/0',
                    awareness_score_percentage: awareness_score_percentage || 0,
                });
            } else {
                console.error('Failed to submit assessment');
                setResult({
                    message: 'Failed to submit assessment. Please try again later.',
                    score: '0/0',
                    awareness_score_percentage: 0,
                });
            }
        } catch (error) {
            console.error('Error submitting assessment:', error);
            setResult({
                message: t('Error submitting assessment. Please try again later.'),
                score: '0/0',
                awareness_score_percentage: 0,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <div className="row" style={{height:'100vh',alignItems:"center",flexDirection:"column",marginTop:'100px'}}>
                <div className="loginLogo mt-5" align="center">
                    <img src="/images/logo.png" alt="Cyber Sense" />
                </div>
                <div className="col-md-6">
                    <div className="assessmentBox card mt-5 p-3" style={{ borderRadius: '30px' }} align="center">
                        <div className="card-body">

                            <h6 className="questionTitle">{t('Please answer the following question:')}</h6>

                            {error && (
                                <div className="alert alert-danger mx-5">{error}</div>
                            )}

                            {questions.length > 0 && !isCompleted && (
                                <div key={questions[currentQuestionIndex].question_id} className="mb-4">
                                    <h6>{questions[currentQuestionIndex].question_arabic}</h6>

                                    <div className="options mt-3">
                                        {questions[currentQuestionIndex].options.map((option, optionIndex) => (
                                            <div key={optionIndex} className="form-check">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    id={`option-${optionIndex}`}
                                                    name="options"
                                                    value={option}
                                                    checked={selectedOption === option}
                                                    onChange={(e) => setSelectedOption(e.target.value)} // Just update selected option
                                                />
                                                <label className="form-check-label" htmlFor={`option-${optionIndex}`}>
                                                    {t(option)}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {isCompleted && result && (
                                <div className="result mt-4">
                                    <h6>{t('Assessment Result')}</h6>
                                    <p>{result.message}</p>
                                    <p>{t('Score')}: {result.score}</p>
                                    <p>{t('Awareness Score Percentage')}: {result.awareness_score_percentage}%</p>

                                    <button
                                        type="button"
                                        className="btn btn-dark w-100 p-2 mt-4"
                                        onClick={window.location='/dashboard'}
                                    >
                                        {t('Next')}
                                    </button>
                                </div>
                            )}

                            {!isCompleted && (
                                <button 
                                    type="button" 
                                    className="btn btn-dark w-100 p-2 mt-4" 
                                    onClick={handleNextQuestion}
                                >
                                    {t('Next')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Assessment;