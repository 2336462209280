import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './EditLesson.css';

const EditLesson = ({id, parentfetchLessons}) => {

    // console.log('lesson id is ' + id);

    const [lessonId] = useState(id);
    const [show, setShow] = useState(false);
    const [lesson_types, setLessonTypes] = useState([]);

    const fetchLessonTypes = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/lesson-types', {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`, 
                },
            });
          
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessonTypes(result);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLesson = async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(api_url + "/lessons/" + lessonId, {
				mode: 'cors',
				method: "GET",
			  	headers: {
					"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
			  	}
			});
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching lesson details.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
            
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["title_ar"]: data.title_ar,
                ["title_en"]: data.title_en,
                ["description_ar"]: data.description_ar,
                ["description_en"]: data.description_en,
                ["code"]: data.code,
                ["lesson_type_id"]: data.lesson_type_id
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }
    
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => {
        fetchLessonTypes();
        fetchLesson();
        setShow(true);
    }
    const [formData, setFormData] = useState({
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        code: "",
        lesson_type_id: ""
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdateLesson = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/lessons/" + lessonId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Lesson updated successfully!");
                setFormData({
                    title_ar: "",
                    title_en: "",
                    description_ar: "",
                    description_en: "",
                    code: "",
                    lesson_type_id: ""
                });
                parentfetchLessons('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update lesson. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" /> Edit
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">Edit Lesson</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Title (English)</label>
                                <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Title (Arabic)</label>
                                <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Description (English)</label>
                                <textarea name="description_en" className="form-control" onChange={handleChange}>{formData.description_en}</textarea>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Description (Arabic)</label>
                                <textarea name="description_ar" className="form-control" onChange={handleChange}>{formData.description_ar}</textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Code</label>
                                <input type="text" name="code" className="form-control" onChange={handleChange} value={formData.code} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Lesson Type</label>
                                <select name="lesson_type_id" className="form-select" onChange={handleChange} required value={formData.lesson_type_id}>
                                    <option></option>
                                    {lesson_types && lesson_types.map((lesson, index) => (
                                        <option value={lesson.id}>{lesson.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={handleUpdateLesson}>
                        Update Lesson
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditLesson;