import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddDepartment from '../../Components/AddDepartmentModal/AddDepartment';
import EditDepartment from '../../Components/EditDepartmentModal/EditDepartment';
import './Department.css';
import { useTranslation } from 'react-i18next';


const Department = () => {
  const [data, setDepartment] = useState([]);
  const [links, setLinks] = useState([]);
  const [deleted, setDeleted] = useState('');
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState('');




  // Handle department deletion
  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${api_url}/admin/main/departments/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setDeleted('Department deleted successfully!');
        // Refresh data after successful deletion
        fetchDepartment('');
      } else {
        setDeleted('Error! Failed to delete department. Please try again.');
      }
    } catch (error) {
      setDeleted(`Error! ${error.message}`);
    }
  }; 
  useEffect(() => {

    if (searchVal !== '') {
      const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchVal.toLowerCase())
      );
      setDepartment(filteredData); // Update filtered data
       }  else{
      fetchDepartment(''); // Update filtered data
	  }
      }, [searchVal]); // Re-run whenever searchVal or allData changes

  // Fetch department data
  const fetchDepartment = async (url) => {
    let page = 1;
    if (url) {
      const temp = url.split('?page=');
      page = temp[1];
    }

    try {
      const token = localStorage.getItem('userToken');
      const response = await fetch(`${api_url}/admin/main/departments?page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setDepartment(result.data);
      setLinks(result.links);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchDepartment('');
  }, []);

  // Define table columns
  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <img src="/images/fi_link.png" alt="Department Name" />{t('Department Name')} 
          </div>
                     

        ),
        colName: 'Name',
      },
      {
        accessorKey: 'created_at',
        header: () => (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <img src="/images/fi_clock.png" alt="Date of Creation" />{t('Date of Creation')} 
            </div>
        ),
       
        cell: ({ row }) => {
            const rawDate = row.original.created_at; // Get the raw date string
            const formattedDate = new Date(rawDate).toLocaleDateString('en-US', {
               
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                
            });
            
            return formattedDate; // Return formatted date
        },
        colName: 'Created_at',
    },
    
      {
        accessorKey: 'id',
        header: () => (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', }}>
            <img src="/images/fi_alert-octagon.png" alt="Action" />{t('Action')}
          </div>
        ),
        cell: ({ getValue }) => {
          const id = getValue();
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <EditDepartment id={id}  onfetchDepartment={fetchDepartment}/>
              <div
                className="iconDel"
                onClick={() => handleDelete(id)}
                style={{ cursor: 'pointer', color: 'red' }}
              >
                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')} 
              </div>
            </div>
          );
        },
        colName: 'ID',
      },
    ],
    []
  );

  // Create a table instance
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="p-4">
      <div className="pageTitle mb-4">{t('Departments')}</div>

      

      <div className="primaryBlueBox p-5 mb-4">
        <div className="row">
          <div className="col-md-4">
            <label className="form-label">{t('Department By Name')}</label>
            <div className="input-group">
            <input
                type="text"
                className="form-control"
                placeholder="Search by department name"
                aria-label="Search by department name"
                value={searchVal} // Bind input value to searchVal state
                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
              />
              <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </div>
          <div className="col-md-1">
            <div className="vrLine"></div>
          </div>
          <div className="col-md-7">
            <AddDepartment  onfetchDepartment={fetchDepartment} />
            
          </div>
        </div>
      </div>
      { deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
      <div className="bg-light px-3 py-3 userTableWrapper resTable">
      
      {window.innerWidth < 768 ? (
              // Render stacked view for small screens
              table.getRowModel().rows.map(row => (
                <div key={row.id} className="stacked-row">
                  {row.getVisibleCells().map(cell => (
                    <div key={cell.id} className="stacked-cell">
                      {cell.column.columnDef.colName != "ID" ? (
                          <strong>{cell.column.columnDef.colName}: </strong>
                      ) : ('')}
                      
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  ))}
                </div>
              ))
            ) : (
      <div className="table-responsive">
              <table className="table table-bordered table-hover">
                  <thead className="thead-dark">
                      {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                              {headerGroup.headers.map(header => (
                                  <th key={header.id}>
                                      {flexRender(header.column.columnDef.header, header.getContext())}
                                  </th>
                              ))}
                          </tr>
                      ))}
                  </thead>
      
                  <tbody>
                      {table.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                              {row.getVisibleCells().map(cell => (
                                  <td key={cell.id}>
                                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                              ))}
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
            )}
          
      </div>


      <div className="navigationWrapper">
        <ul>
          {links.map((link, index) => (
            <li
              key={index}
              className={`${link.active ? 'active' : ''} ${
                link.url === null ? 'disabled' : ''
              }`}
              onClick={link.url !== null ? () => fetchDepartment(link.url) : undefined}
            >
              <div dangerouslySetInnerHTML={{ __html: link.label }} />
            </li>
          ))}
        </ul>
      </div>
     
    </div>
  );
};

export default Department;
