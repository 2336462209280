import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

const Sync = ({ id, parentfetchLDAPServer }) => {
    const ldap_id = id;
    const [show, setShow] = useState(false); // Correct syntax for declaring state
    const [syncing, setSyncing] = useState(false); // To track sync progress
    const [error, setError] = useState(null); // To store error message
    const { t } = useTranslation();
    
    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSync = async () => {
        setSyncing(true); // Start syncing
        try {
            const response = await fetch(`${api_url}/sync`, {
                method: 'POST', // Assuming it's a POST request
                headers: {
                    'Authorization': `Bearer YOUR_ACCESS_TOKEN`, // Replace with actual token
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ server_id: ldap_id }), // Pass the server_id for sync initiation
            });
    
            if (!response.ok) {
                throw new Error('Failed to initiate sync');
            }
    
            const result = await response.json();
            if (result.message === 'Sync command initiated.') {
                alert(t('Sync initiated successfully!'));
                handleClose(); // Close the modal after successful sync initiation
                parentfetchLDAPServer(); // Refresh or fetch data after sync
            } else {
                // Handle case where message is not what was expected
                throw new Error(result.message || 'Unknown error');
            }
        } catch (error) {
            setError(error.message || t('An error occurred while initiating sync.'));
        } finally {
            setSyncing(false); // Stop syncing
        }
    };
    

    return (
        <div>
            <div className="iconEdit" onClick={handleShow}>
            <img className="sync-icon" src="/images/images (1).png" alt="Sync" style={{ height: '15px' }} />
                <span style={{ color: 'green' }}>{t('Sync')}</span>


            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Sync')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="addUserWrapper">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {/* Add any additional content you want to display inside the modal */}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('Close')}
                    </Button>

                    <Button variant="primary" onClick={handleSync} disabled={syncing}>
                        {syncing ? t('Syncing...') : t('Sync initiated')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Sync;
