import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

const  AddPhishingCompaign = ({fetchPhishingCompaign}) => { 
    const { t } = useTranslation();



    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        name_ar: "",
        name_en: "",
        start_date: "",
        end_date: "",
        template_id: "",
        save_submitted_data: "",
        enable_attachment: "",
        attachment: "",
        execute_url:"",
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddPhishingCompaign = async (e) => {
        e.preventDefault();
    
        console.log(formData);
    
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/phishing-campaigns", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
    
            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage("Phishing Campaign added successfully!");
                setFormData({
                    name_ar: "",
                    name_en: "",
                    start_date: "",
                    end_date: "",
                    template_id: "",
                    save_submitted_data: "",
                    enable_attachment: "",
                    attachment: "",
                    execute_url:"",
                });
    
                fetchPhishingCompaign('');
            }  else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to add Phishing compaign. Please try again.");

            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    
    };
    
    

    return (
        <div>
            <label style={{marginTop:'10px'}}>{t('Add New Phishing Compaign')}</label>
            <br />
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add Phishing Compaign" />{t('Add')} 
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Add New Phishing Compaign')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="adddepatmentWrapper">
                <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name ar ')}</label>
                                <input type="text" name="name_ar" className="form-control" onChange={handleChange} value={formData.name_ar} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name en')}</label>
                                <input type="text" name="name_en" className="form-control" onChange={handleChange} value={formData.name_en} />
                            </div>
                        </div>
                    </div>
                  
                    <div className="row">
                        <div className="col-md-6">
                        <div className="mb-3">
                                <label className="form-label">{t('Start date')}</label>
                                <input type="datetime-local" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="mb-3">
                                <label className="form-label">{t('End date')}</label>
                                <input type="datetime-local" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Template id')}</label>
                                <input type="text" name="template_id" className="form-control" onChange={handleChange} value={formData.template_id} />
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="mb-3">
                            <label className="form-label">{t('Save Submitted Data')}</label>
                            <select
                                name="save_submitted_data"
                                className="form-select form-select-lg" // Bootstrap classes for styling
                                onChange={handleChange}
                                value={formData.save_submitted_data}
                                required // Ensures the field is mandatory
                            >
                                <option value=""> Select</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </select>
                        </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                        <div className="mb-3">
                        <label className="form-label">{t('Enable Attachment')}</label>
                        <select
                            name="enable_attachment"
                            className="form-select"
                            onChange={handleChange}
                            value={formData.enable_attachment}
                            required // Ensures the field is mandatory
                        >
                            <option value="">Select</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                        {/* Optional feedback message */}
                        <div className="invalid-feedback">
                            Please select a value.
                        </div>
                    </div>

                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Attachment')}</label>
                                <input type="text" name="attachment" className="form-control" onChange={handleChange} value={formData.attachment} />
                            </div>
                        </div>
                        <div className="mb-3">
                                <label className="form-label">{t('Execute url')}</label>
                                <input type="text" name="execute_url" className="form-control" onChange={handleChange} value={formData.execute_url} />
                            </div>
                    </div>
                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')} 
                    </Button>

                    <Button variant="primary" onClick={handleAddPhishingCompaign}>
                    {t('Add Phishing Compaign')} 
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddPhishingCompaign;
