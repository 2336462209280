import React, { useState } from "react";
import { api_url } from "../../constant/constant";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";

function UpdatePassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        // Check if passwords match
        if (password !== confirmPassword) {
            setError(t("Passwords do not match."));
            return;
        }

        setLoading(true);

        // Get user data from localStorage
        const userData = JSON.parse(localStorage.getItem("userData"));
        const token = localStorage.getItem("userToken");

        if (!userData) {
            setError(t("User not authenticated."));
            setLoading(false);
            return;
        }

        const userId = userData.id; // Fetch user_id from userData

        try {
            const response = await fetch(`${api_url}/change-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    user_id: userId,
                    password: password,
                    password_confirmation: confirmPassword,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || t("Failed to update password."));
                throw new Error(errorData.message);
            }

            const data = await response.json();

            if (data.status === "success") {
                setSuccess(data.message || t("Password updated successfully."));

                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/login";
                }, 2000);
            } else {
                setError(t("Something went wrong. Please try again."));
            }
        } catch (err) {
            console.error(err.message);
            setError(t("An error occurred. Please try again."));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container" style={{  margin: "0 auto", marginTop: "5%" }}>

            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}

            <form onSubmit={handleSubmit}>
                <div className="mb-3" style={{width:"30%"}}>
                    <label htmlFor="password" className="form-label">
                        {t("New Password")}
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3" style={{width:"30%"}}>
                    <label htmlFor="confirmPassword" className="form-label">
                        {t("Confirm Password")}
                    </label>
                    <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <Button variant="primary">
                 {t('Update User')}
            </Button>
            </form>
        </div>
    );
}

export default UpdatePassword;
