import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditAnswer.css';
import { useTranslation } from 'react-i18next';
const EditAnswers = ({question_id, id, parentfetchAnswers}) => {

    // console.log('lesson id is ' + id);
    // const { question_id } = useParams(question_id); // Assuming `id` is a valid variable available in scope
    const [answerid] = useState(id); // Use camelCase for naming consistencyws
    const [show, setShow] = useState(false); // Correct syntax for declaring state
    const { t } = useTranslation();
   

    const fetchAnswers= async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/questions/${question_id}/answers/${answerid}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching Answer details.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
            
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["answer_ar"]: data.answer_ar,
                ["answer_en"]: data.answer_en,
                ["is_correct"]: data.is_correct
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    useEffect(() => {
        // fetchQuestionTypes();
        fetchAnswers();
    }, []);

    
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        answer_ar: "",
        answer_en: "",
        is_correct: ""
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? (checked ? 1 : 0) : value, // Convert checkbox value to 1 or 0
        });
      };

    const handleUpdateAnswers = async (e) => {
        e.preventDefault();
      
        try {
          const token = localStorage.getItem("userToken");
          const response = await fetch(`${api_url}/questions/${question_id}/answers/${answerid}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          });
      
          if (response.ok) {
            setErrorMessage("");
            setSuccessMessage("Answer updated successfully!");
           
            parentfetchAnswers("");
          } else {
            setSuccessMessage("");
            setErrorMessage("Failed to update Answer. Please try again.");
          }
        } catch (error) {
          setSuccessMessage("");
          setErrorMessage(error.message);
        }
      };
      

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" />{t('Edit')}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Edit Answers')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Answer (English)')}</label>
                                <input type="text" name="answer_en" className="form-control" onChange={handleChange} value={formData.answer_en} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Answer (Arabic)')}</label>
                                <input type="text" name="answer_ar" className="form-control" onChange={handleChange} value={formData.answer_ar} />
                            </div>
                        </div>
                        <div className="form-check">
                            <input
                            className="form-check-input"
                            type="checkbox"
                            name="is_correct"
                            id="flexCheckIndeterminate"
                            onChange={handleChange}
                            checked={formData.is_correct === 1} // Bind checkbox value
                            />
                            <label className="form-check-label" htmlFor="flexCheckIndeterminate">
                            {t('Correct')}
                            </label>
                        </div>
                    </div>
                
                      

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}Close
                    </Button>

                    <Button variant="primary" onClick={handleUpdateAnswers}>
                    {t('Update Answer')}  
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditAnswers;