import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddUser from '../../Components/AddUserModal/AddUser';
// import EditUser from '../../Components/EditUserModal/EditUser';
import BulkUserImport from '../../Components/BulkUserImport/BulkUserImport';
import './Users.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Users = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [searchVal, setSearchVal] = useState('');
    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [loading, setLoading] = useState(false); // Loader state

    const handleDelete = async (id) => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/users/destory/" + id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (response.ok) {
                setDeleted("User deleted successfully!");
            }
            else {
                setDeleted("Error!! Failed to delete user. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
        }

    }
    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.firstname?.toLowerCase().includes(searchVal?.toLowerCase() || '') ||
                item.lastname?.toLowerCase().includes(searchVal?.toLowerCase() || '') ||
                item.source_name?.toLowerCase().includes(searchVal?.toLowerCase() || '') ||
                item.role_name?.toLowerCase().includes(searchVal?.toLowerCase() || '')
            );
            setUsers(filteredData);
        } else {
            fetchUsers(''); // Fetch all data if no search value
        }
    }, [searchVal])


    const fetchUsers = async (url) => {
        setLoading(true); // Show loader before API call
        console.log(url);
        var page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            var page = temp[1];
        }


        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/admin/main/users?page=' + page, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUsers(result.data);
            setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            //localStorage.removeItem("userToken");
            //localStorage.removeItem("userData");
            //window.location = "/login";
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers('');
    }, []);
    // Define table columns
    const columns = React.useMemo(
        () => [

            {
                accessorKey: 'firstname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="First Name" />{t('First Name')}
                    </div>
                ),
                colName: 'First Name',
            },
            {
                accessorKey: 'lastname',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_user.svg" alt="Last Name" />{t('Last Name')}
                    </div>
                ),
                colName: 'Last Name',
            },
            {
                accessorKey: 'source_name',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_align-left.svg" alt="Source" />{t('Source')}
                    </div>
                ),
                colName: 'Source Name',
            },
            {
                accessorKey: 'status_id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_loader.svg" alt="Source" />{t('Status')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const status = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            {status === 1 ? (
                                <div className="statusActive badgeActive">
                                    {t('Active')}
                                </div>
                            ) : (
                                <div className="statusInactive badgeInactive">
                                    {t('Inactive')}
                                </div>
                            )}
                        </div>
                    );
                },
                colName: 'Status ID',
            },
            {
                accessorKey: 'role_name',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_tool.svg" alt="Source" />{t('Role')}
                    </div>
                ),
                colName: 'Role Name',
            },
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            {/* <EditUser id={id} onfetchUsers={fetchUsers} /> */}
                            <div className="iconDel" onClick={() => { setLoading(true); handleDelete(id); setLoading(false); }}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'Action',
            },
        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleSurveyRowClick = (id) => {
        navigate(`/UserManagement/${id}/UserDetail`)
    }

 return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('All Users')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search User')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search user"
                                aria-label="Search by user"
                                value={searchVal} // Bind input value to searchVal state
                                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <div className='d-flex gap-5'>
                            <AddUser onfetchUsers={fetchUsers} />
                            <BulkUserImport onfetchUsers={fetchUsers} />
                        </div>

                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}

            {loading ? (
                <div className="d-flex justify-content-center align-items-center  mt-5">
                    <div className="spinner-border loader-api" role="status" style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable ">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}


            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchUsers(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
}

export default Users;