import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditLDAPServer.css';
import { useTranslation } from 'react-i18next';

const EditLearningManager = ({id, parentfetchLDAPServer}) => {

    const ldap_id = id;
    const { t } = useTranslation();


 const [Security_types, setSecurity_types] = useState([]);

 const fetchldapsecuritytypes = async () => {
   try {
     const token = localStorage.getItem("userToken");
     const response = await fetch(api_url + '/ldapsecuritytypes', {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`, 
       },
     });
 
     if (!response.ok) {
       throw new Error(`HTTP error! status: ${response.status}`);
     }
 
     const result = await response.json();
     console.log(result); // Verify the API response
     setSecurity_types(result.data || result); // Adjust based on API structure
   } catch (error) {
     console.error('Error fetching data:', error);
   }
 };
 const [Server_types1, fetchserverTypes1] = useState([]);
 const fetchldapserverTypes1 = async () => {
   try {
     const token = localStorage.getItem("userToken");
     const response = await fetch(api_url + '/ldapservertypes', {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${token}`, 
       },
     });
 
     if (!response.ok) {
       throw new Error(`HTTP error! status: ${response.status}`);
     }
 
     const result = await response.json();
     console.log(result); // Verify the API response
     fetchserverTypes1(result.data || result); // Adjust based on API structure
   } catch (error) {
     console.error('Error fetching data:', error);
   }
 };
  const fetchLDAPServer= async() => {
 
         try 
        {
             const token = localStorage.getItem("userToken");
             const response = await fetch(`${api_url}/ldap_servers/${ldap_id}`, {
                 method: "GET",
                 headers: {
                   "Content-Type": "application/json",
                   Authorization: `Bearer ${token}`,
                 },
               });
      
            if (!response.ok) 
            {
                setErrorMessage("Error fetching LDAP Server details.");
                 setSuccessMessage("");
            }
      
            const data = await response.json();
             
            setErrorMessage("");
             setSuccessMessage("");
            
             setFormData({
                 ...formData,
                 ["title"]: data.title,
                 ["host"]: data.host,
                 ["port"]: data.port,
                 ["server_type"]: data.server_type,
                 ["security_type"]: data.security_type,
                 ["bind_dn"]: data.bind_dn,
                 ["password"]: data.password,
                 ["base"]: data.base,
                 ["filter"]: data.filter,
                 ["map_user_name"]: data.map_user_name,
                 ["map_first_name"]: data.map_first_name,
                 ["map_last_name"]: data.map_last_name,
                 ["map_email"]: data.map_email,
                 ["map_department"]: data.map_department,
                 ["map_phone_number"]: data.map_phone_number,
                 ["map_group"]: data.map_group,
                 ["enable_group_sync"]: data.enable_group_sync,
                 ["map_group_filter"]: data.map_group_filter,
             });
 
        } 
        catch (err) 
        {
            setErrorMessage(err.message);
             setSuccessMessage("");
        }
     }
 
     useEffect(() => {
         // fetchQuestionTypes();
         fetchldapserverTypes1();
         fetchLDAPServer();
         fetchldapsecuritytypes();

     }, []);
 
     
     const handleClose = () => {
         setSuccessMessage('');
         setErrorMessage('');
     };
     const handleShow = () =>{ 
         fetchldapsecuritytypes();
         fetchldapserverTypes1();
         fetchLDAPServer();
     }
 
     const [formData, setFormData] = useState({
         title: "",
         host: "",
         port: "",
         server_type: "",
         security_type: "",
         bind_dn: "",
         password: "",
         base: "",
         filter: "",
         map_user_name: "",
         map_first_name: "",
         map_last_name: "",
         map_email: "",
         map_department: "",
         map_phone_number: "",
         map_group: "",
         enable_group_sync: "",
         map_group_filter: "",
         
       });
 
     const [successMessage, setSuccessMessage] = useState('');
     const [errorMessage, setErrorMessage] = useState('');
 
     const handleChange = (e) => {
         const { name, value } = e.target;
         setFormData({
           ...formData,
           [name]: value,
         });
     };

   

    const handleUpdateLDAPServer = async (e) => {
           e.preventDefault();
   
           console.log(formData);
   
           try {
               const token = localStorage.getItem("userToken");
               const response = await fetch(`${api_url}/ldap_servers/${ldap_id}`, {
                   method: "PUT",
                   headers: {
                       "Content-Type": "application/json",
                       "Authorization": `Bearer ${token}`, 
                   },
                   body: JSON.stringify(formData),
                   redirect: "follow"
               });
               
   
               if (response.ok) 
               {
                   setErrorMessage("");
                   setSuccessMessage("LDAP Server updated successfully!");
                  
                   parentfetchLDAPServer('');
               } 
               else 
               {
                   setSuccessMessage("");
                   setErrorMessage("Failed to update LDAP Server. Please try again.");
               }
           }
           catch (error) {
               setSuccessMessage("");
               setErrorMessage(error);
           }
       }

    return (
        <div className="py-4">

<div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Title')}</label>
                                <input type="text" name="title" className="form-control" onChange={handleChange} value={formData.title} />
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Host')}</label>
                                <input type="text" name="host" className="form-control" onChange={handleChange} value={formData.host} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Port')}</label>
                                <input type="text" name="port" className="form-control" onChange={handleChange} value={formData.port} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                            <label className="form-label">{t('Server type')}</label>
                                <select name="server_type" className="form-select" onChange={handleChange} value={formData.server_type}>
                                    <option></option>
                                    {Server_types1 && Server_types1.map((Server, index) => (
                                        <option value={Server}>{Server}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Bind dn')}</label>
                                <input type="text" name="bind_dn" className="form-control" onChange={handleChange} value={formData.bind_dn} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Password')}</label>
                                <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Base')}</label>
                                <input type="text" name="base" className="form-control" onChange={handleChange} value={formData.base} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Filter')}</label>
                                <input type="text" name="filter" className="form-control" onChange={handleChange} value={formData.filter} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map user name')}</label>
                                <input type="text" name="map_user_name" className="form-control" onChange={handleChange} value={formData.map_user_name} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map first name')}</label>
                                <input type="text" name="map_first_name" className="form-control" onChange={handleChange} value={formData.map_first_name} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map last name')}</label>
                                <input type="text" name="map_last_name" className="form-control" onChange={handleChange} value={formData.map_last_name} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map  email')}</label>
                                <input type="text" name="map_email" className="form-control" onChange={handleChange} value={formData.map_email} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map department')}</label>
                                <input type="text" name="map_department" className="form-control" onChange={handleChange} value={formData.map_department} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map phone number')}</label>
                                <input type="text" name="map_phone_number" className="form-control" onChange={handleChange} value={formData.map_phone_number} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map group')}</label>
                                <input type="text" name="map_group" className="form-control" onChange={handleChange} value={formData.map_group} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Enable group sync')}</label>
                                <input type="text" name="enable_group_sync" className="form-control" onChange={handleChange} value={formData.enable_group_sync} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label">{t('Map group filter')}</label>
                                <input type="text" name="map_group_filter" className="form-control" onChange={handleChange} value={formData.map_group_filter} />
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="mb-3">
                                <label className="form-label">{t('Security type ')}</label>
                                <select name="security_type" className="form-select" onChange={handleChange} required  value={formData.security_type}>
                                    <option></option>
                                    {Security_types && Security_types.map((Security, index) => (
                                        <option value={Security}>{Security}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

            <Button variant="primary" onClick={handleUpdateLDAPServer}>
            {t('UpdateLDAPServer')}
            </Button>
        </div>
      );

}

export default EditLearningManager;