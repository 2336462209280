import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddEmail from '../../Components/AddEmailServerModal/AddEmail';
import EditEmail from '../../Components/EditEmailServerModal/EditEmail';
import { useTranslation } from 'react-i18next';

import './Email.css';

const Email = () => {

    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState('');
    const [loading, setLoading] = useState(false); // Loader state
    



    const handleDelete = async(id) => {
        
        try {
            const token = localStorage.getItem("userToken");
             const response = await fetch(`${api_url}/email-servers/${id}`, {
                    method: 'DELETE',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${token}`,
                    },
            });

            if (response.ok) 
            {
                setDeleted("Email server deleted successfully!");
                fetchEmailServer('');
            } 
            else 
            {
                setDeleted("Error!! Failed to delete email server. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
        }

    }
useEffect(() => {

    if (searchVal !== '') {
      const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.username.toLowerCase().includes(searchVal.toLowerCase())||
      item.password.toLowerCase().includes(searchVal.toLowerCase())||
      item.host.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.encryption.toLowerCase().includes(searchVal.toLowerCase()) 
      );
      setUsers(filteredData); // Update filtered data
       }  else{
        fetchEmailServer(''); // Update filtered data
      }
      }, [searchVal]);
    
    const fetchEmailServer = async (url) => {
        setLoading(true);
        console.log(url);
        var page = 1;
        if (url !== '')
        {
            const temp = url.split('?page=');
            page = temp[1];
        }
        

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-servers?page=${page}`, {
              method: "GET",
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
              },
          });
              ;
          
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }

            const result = await response.json();
            setUsers(result.data);
            setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            //localStorage.removeItem("userToken");
            //localStorage.removeItem("userData");
            //window.location = "/login";
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmailServer('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
        {
            accessorKey: 'name',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                 <i class="bi bi-clipboard-data"></i>{t('Name')} 
                </div>
            ),
            colName: ' Name',

        },
        {
            accessorKey: 'username',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                 <i class="bi bi-clipboard-data"></i>{t('Username')}
                </div>
            ),
            colName: ' Username',
        },
        {
            accessorKey: 'password',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <i class="bi bi-clipboard-data"></i>{t('Password')} 
                </div>
            ),
            colName: ' Password',

        },
        // {
        //     accessorKey: 'status_id',
        //     header: () => (
        //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //           <img src="/images/fi_loader.svg" alt="Source" /> Status
        //         </div>
        //     ),
        //     cell: ({ getValue }) => {
        //         const status = getValue();
        //         return (
        //           <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        //             {status === 1 ? (
        //                 <div className="statusActive badgeActive">
        //                     Active
        //                 </div>
        //             ) : (
        //                 <div className="statusInactive badgeInactive">
        //                     Inactive
        //                 </div>
        //             )}
        //           </div>
        //         );
        //     },
        // },
        {
            accessorKey: 'host',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
             <i class="bi bi-clipboard-data"></i>{t('Host')}
                </div>
            ),
            colName: ' Host',

        },
        {
          accessorKey: 'port',
          header: () => (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <i class="bi bi-clipboard-data"></i>{t('Port')} 
              </div>
          ),
          colName: ' Port',

      },
      {
        accessorKey: 'encryption',
        header: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <i class="bi bi-clipboard-data"></i>{t('Encryption')}
            </div>
        ),
        colName: ' Encryption',

    },
        {
            accessorKey: 'id',
            header: () => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')} 
                </div>
            ),
            cell: ({ getValue }) => {
                const id = getValue();
                return (
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <EditEmail id={id}  onfetchEmail={fetchEmailServer}/>
                    <div className="iconDel" onClick={() => handleDelete(id)}>
                        <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')} 
                    </div>
                  </div>
                );
            },
            colName: 'ID',
        },
       

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">

            <div className="pageTitle mb-4">{t('All Email Server')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search Email Server')}</label>
                        <div className="input-group">
                            <input
                            type="text"
                            className="form-control"
                            placeholder="Search by email Server"
                            aria-label="Search by email Server"
                            value={searchVal} // Bind input value to searchVal state
                            onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <AddEmail onfetchEmail={fetchEmailServer}/>
                    </div>
                </div>
            </div>

            { deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}

{loading ? (
    <div className='d-flex justify-content-center align-items-center my-5'>
        <div className='spinner-border' role='status' style={{
             borderTopColor: "#6897CB",
             borderBottomColor: "#6897CB",
             borderLeftColor: "#6897CB",
             marginTop: "50px"
        }}>

        </div>
    </div>
) : (
    
           <div className="bg-light px-3 py-3 userTableWrapper resTable">
           
           {window.innerWidth < 768 ? (
                   // Render stacked view for small screens
                   table.getRowModel().rows.map(row => (
                     <div key={row.id} className="stacked-row">
                       {row.getVisibleCells().map(cell => (
                         <div key={cell.id} className="stacked-cell">
                           {cell.column.columnDef.colName != "ID" ? (
                               <strong>{cell.column.columnDef.colName}: </strong>
                           ) : ('')}
                           
                           {flexRender(cell.column.columnDef.cell, cell.getContext())}
                         </div>
                       ))}
                     </div>
                   ))
                 ) : (
           <div className="table-responsive">
                   <table className="table table-bordered table-hover">
                       <thead className="thead-dark">
                           {table.getHeaderGroups().map(headerGroup => (
                               <tr key={headerGroup.id}>
                                   {headerGroup.headers.map(header => (
                                       <th key={header.id}>
                                           {flexRender(header.column.columnDef.header, header.getContext())}
                                       </th>
                                   ))}
                               </tr>
                           ))}
                       </thead>
           
                       <tbody>
                           {table.getRowModel().rows.map(row => (
                               <tr key={row.id}>
                                   {row.getVisibleCells().map(cell => (
                                       <td key={cell.id}>
                                           {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                       </td>
                                   ))}
                               </tr>
                           ))}
                       </tbody>
                   </table>
               </div>
                 )}
               
           </div>
)}
           


            <div className="navigationWrapper">
                <ul>
                {links.map((link, index) => (
                    
                    <li key={index} 
                        className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                        onClick={link.url !== null ? () => fetchEmailServer(link.url) : undefined}
                        >
                        <div dangerouslySetInnerHTML={{ __html: link.label }} />
                    </li>
                ))}
                </ul>
            </div>

        </div>
    );
}

export default Email;