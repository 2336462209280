import { Modal, Button } from "react-bootstrap";
import React, {  useEffect } from 'react';
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddLearningManager.css';
import { useTranslation } from 'react-i18next';

const AddLearningManager = ({parentfetchLearningManager}) => {
    const { t } = useTranslation();

 const [Learning_types, setLearningTypes] = useState([]);

const fetchLearningManagerTypes = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(api_url + '/learning-campaigns/types', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, 
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result); // Verify the API response
    setLearningTypes(result.data || result); // Adjust based on API structure
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

useEffect(() => {
    fetchLearningManagerTypes();
}, []);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        type: "",
        title_ar: "",
        title_en: "",
        description_ar:"",
        description_en:"",
        start_date: "",
        start_time: "",
        end_date: "",
        video_seek: "",
        
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleAddLearningManager = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/learning-campaigns", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Learnig manager server added successfully!");
                setFormData({
                    type: "",
                    title_ar: "",
                    title_en: "",
                    description_ar:"",
                    description_en:"",
                    start_date: "",
                    start_time: "",
                    end_date: "",
                    video_seek: "",
                
                });

                parentfetchLearningManager('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to add Learnig manager. Please try again.");

            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>
            <label style={{marginTop:'10px'}}>{t('Add New Learning Campaign')}</label>
            <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add Learnig Campaign" /> {t('Add')}
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle" >{t('Add New Learning Campaign')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">{t('Type')}</label>
                                <select name="type" className="form-select" onChange={handleChange} required>
                                    <option></option>
                                    {Learning_types && Learning_types.map((Learning, index) => (
                                        <option value={Learning}>{Learning}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Title ar')}</label>
                                <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Title en')}</label>
                                <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en}  required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Description ar')}</label>
                                <input type="text" name="description_ar" className="form-control" onChange={handleChange} value={formData.description_ar} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Description en')}</label>
                                <input type="text" name="description_en" className="form-control" onChange={handleChange} value={formData.description_en} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Start date')}</label>
                                <input type="date" name="start_date" className="form-control" onChange={handleChange} value={formData.start_date}  required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Start time')}</label>
                                <input type="time" name="start_time" className="form-control" onChange={handleChange} value={formData.start_time} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('End date')}</label>
                                <input type="date" name="end_date" className="form-control" onChange={handleChange} value={formData.end_date}required  />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Video seek')}</label>
                                <input type="text" name="video_seek" className="form-control" onChange={handleChange} value={formData.video_seek} required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                {/* <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select> */}
                            </div>
                        </div>
                        {/* <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                        {/* </div> */}
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')} 
                    </Button>

                    <Button variant="primary" onClick={handleAddLearningManager}>
                    {t('Add Learning campaign')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default AddLearningManager;