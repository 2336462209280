import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { api_url } from "../../constant/constant";
import { useTranslation } from 'react-i18next';

import './AddQuestion.css';

const Question = ({ parentfetchQuestion}) => {
  const { lesson_id } = useParams(); // Get lessonId from the URL
  
  const { t } = useTranslation();



  
      const [show, setShow] = useState(false);
      const handleClose = () => {
          setSuccessMessage('');
          setErrorMessage('');
          setShow(false);
      };
      const handleShow = () => setShow(true);
  
      const [formData, setFormData] = useState({
        question_en: "",
          question_ar: "",
          lesson_id: ""
        });
  
      const [successMessage, setSuccessMessage] = useState('');
      const [errorMessage, setErrorMessage] = useState('');
  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
            ...formData,
            [name]: value,
          });
      };
  
    const handleAddQuestion = async (e) => {
    e.preventDefault();

    console.log(formData);

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/lessons/${lesson_id}/questions`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
            redirect: "follow",
        });

        if (response.ok) {
            setErrorMessage("");
            setSuccessMessage("Question added successfully!");
            setFormData({
                question_en: "",
                question_ar: "",
                lesson_id: "",
            });
            parentfetchQuestion('');
           
        } else {
            setSuccessMessage("");
            setErrorMessage("Failed to add Question. Please try again.");
        }
    } catch (error) {
        setSuccessMessage("");
        setErrorMessage(error.message);
    }
};


  return (
    <div>
      {/* <h1> {lessonid}</h1> */}
      <label>{t('Add New Question')}</label>
      <br/>
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="Add User" />{t('Add')}
            </NavLink>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Add New Question')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        {/* <div className="mb-3">
            <label className="form-label">Lesson ID</label>
            <input
              type="text"
              name="lesson_id"
              className="form-control"
              onChange={handleChange}
              value={formData.lesson_id}
            />
          </div> */}
          <div className="mb-3">
            <label className="form-label">{t('Question (English)')}</label>
            <input
              type="text"
              name="question_en"
              className="form-control"
              onChange={handleChange}
              value={formData.question_en}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">{t('Question (Arabic)')} </label>
            <input
              type="text"
              name="question_ar"
              className="form-control"
              onChange={handleChange}
              value={formData.question_ar}
            />
          </div>

         

          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          {t('Close')}
          </Button>
          <Button variant="primary" onClick={handleAddQuestion}>
          {t('Add Question')}
         
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Question;
