import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import AddPolice from '../../Components/AddPoliceModal/AddPolice';
import EditPolice from '../../Components/EditPoliceModal/EditPolice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Police = () => {




    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // State variables
    const [data, setLessons] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const [loading, setLoading] = useState(false);
    // Handle navigation to Question page with lesson id
    const handleQuestionClick = (id) => {
        navigate(`/question/${id}`); // Pass lesson id as a route parameter
    };

    // Handle delete user action
    const handleDelete = async (id) => {
        setLoading(true);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/policies/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setDeleted("Police deleted successfully!");
                fetchPolice('');
            }
            else {
                setDeleted("Error!! Failed to delete Police. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
            setLoading(false);
        } finally {
            setLoading(false);
        }

    }
    useEffect(() => {

        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.name_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.name_ar.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.appearance_name_en.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.appearance_name_ar.toLowerCase().includes(searchVal.toLowerCase())
            );
            setLessons(filteredData); // Update filtered data
        } else {
            fetchPolice(''); // Update filtered data
        }
    }, [searchVal]);
    // Fetch questions data with pagination
    const fetchPolice = async (url) => {
        setLoading(true);
        let page = 1;

        // Determine page from URL if provided
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1; // Ensure page defaults to 1 if the split fails
        }

        try {
            const token = localStorage.getItem("userToken");

            // Fetch data from the API
            const response = await fetch(`${api_url}/policies?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            // Extract 'current_page' and 'data'
            const { current_page, data } = result;

            console.log('Current Page:', current_page); // Log current page
            console.log('Data:', data); // Log data array

            // Use the data as needed
            setLessons(data); // Assuming setLessons is a state function for data
            // Optionally handle current_page if needed
            // Example: setCurrentPage(current_page);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
            // Handle errors, optionally redirect to login
            // localStorage.removeItem("userToken");
            // localStorage.removeItem("userData");
            // window.location = "/login";
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchPolice('');
    }, []);

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name (English)')}
                    </div>
                ),
                colName: 'name_en',
            },
            {
                accessorKey: 'name_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Name (Arabic)')}
                    </div>
                ),
                colName: 'name_ar',
            },
            {
                accessorKey: 'appearance_name_en',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Appearance  (English)')}
                    </div>
                ),
                colName: 'appearance_name_en',
            },
            {
                accessorKey: 'appearance_name_ar',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Appearance  (Arabic)')}
                    </div>
                ),
                colName: 'appearance_name_ar',
            },
            {
                accessorKey: 'version',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Version')}
                    </div>
                ),
                colName: 'version',
            },
            {
                accessorKey: 'policy_type_id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", whiteSpace: "nowrap" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Policy type ')}
                    </div>
                ),
                colName: 'policy_type_id',
            },

            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>


                            <EditPolice id={id} parentfetchPolice={fetchPolice} />

                            {/* Delete button */}
                            <div className="iconDel" onClick={() => handleDelete(id)}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'id', // Correct property spelling
            },

        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('All Policies')}</div>
            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search Policy')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Policy"
                                aria-label="Search Policy"
                                value={searchVal} // Bind input value to searchVal state
                                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <AddPolice parentfetchPolice={fetchPolice} />
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            {loading ? (
                <div className="d-flex justify-content-center align-items-center  mt-5">
                    <div className="spinner-border loader-api" role="status" style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 userTableWrapper resTable">

                    {window.innerWidth < 768 ? (
                        // Render stacked view for small screens
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row">
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        {cell.column.columnDef.colName != "ID" ? (
                                            <strong>{cell.column.columnDef.colName}: </strong>
                                        ) : ('')}

                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="thead-dark">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            )}
            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchPolice(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default Police;
