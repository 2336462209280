import React from 'react';
import { useTranslation } from 'react-i18next';
import { api_url } from '../../constant/constant';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

    const changeLanguage = (lng) => {

		changeUserLanguage(lng);

        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
        document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
        document.getElementsByTagName("body")[0].setAttribute("dir", lng === "ar" ? "rtl" : "ltr");

        const rtlStylesheetId = "bootstrap-rtl";
    	if (lng === "ar") 
		{
      		// Add RTL Bootstrap CSS
      		const link = document.createElement("link");
      		link.id = rtlStylesheetId;
      		link.rel = "stylesheet";
      		link.href = "/styles/bootstrap.rtl.min.css";
      		document.head.appendChild(link);
    	} 
		else 
		{
      		// Remove RTL Bootstrap CSS
      		const link = document.getElementById(rtlStylesheetId);
      		if (link) 
			{
        		document.head.removeChild(link);
      		}
    	}
    };

	const changeUserLanguage = async(lng) => {
		const language_code = (lng === "en") ? 1 : 2;

		try 
		{
			const token = localStorage.getItem("userToken");
			const response = await fetch(api_url + "/admin/main/users/language", {
				mode: 'cors',
				method: "PATCH",
				headers: {
					"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
				},
				body: JSON.stringify({ language_id: parseInt(language_code) }),
			});
		
			if (!response.ok) 
			{
				//setError("Error getting login settings...");
				throw new Error("Error getting login settings...");
			}
		
			const data = await response.json();
			console.log( data );
			
		} 
		catch (err) 
		{
			console.log(err.message);
		}

	};

  return (
    <div className='lngSwitcher1 d-flex ps-1'>
        <a href="javascript:;" className='mx-1' onClick={() => changeLanguage('en')} title="English"><img src="/images/icons8-us-flag.svg" width="24" alt="English" /></a>
        <a href="javascript:;" className='mx-1' onClick={() => changeLanguage('ar')} title="عربی"><img src="/images/icons8-saudi-arabia.svg" width="24" alt="عربی" /></a>
      {/* <button className='mx-1 px-2 py-1' onClick={() => changeLanguage('en')}><img src="/images/icons8-us-flag.svg" width="24" /></button>
      <button className='mx-1 px-2 py-1' onClick={() => changeLanguage('ar')}><img src="/images/icons8-saudi-arabia.svg" width="24" /></button> */}
    </div>
  );
};

export default LanguageSwitcher;