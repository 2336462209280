import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './AddDepartment.css';
import { useTranslation } from 'react-i18next';

const  AddDepartment = ({onfetchDepartment}) => { 
    const { t } = useTranslation();



    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        name: "",
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddDepartment = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/departments", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Department added successfully!");
                setFormData({
                    name: "",
                
                });

                onfetchDepartment('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to add Department. Please try again.");
                
                onfetchDepartment('');

            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }
    

    return (
        <div>
            <label style={{marginTop:'10px'}}>{t('Add New Department')}</label>
            <br />
            <NavLink className="btnWhiteGradient" onClick={handleShow}>
                <img src="/images/fi_user-plus.png" alt="AddDepartment" />{t('Add')} 
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Add New Department')}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="adddepatmentWrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">{t('Department Name')}</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    onChange={handleChange}
                                    value={formData.name} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')} 
                    </Button>

                    <Button variant="primary" onClick={handleAddDepartment}>
                    {t('Add Department')} 
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddDepartment;
