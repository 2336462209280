import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { api_url } from "../../constant/constant";
import './BulkUserImport.css';

const BulkUserImport = ({ onfetchUsers }) => {
    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setFile(null);
        setShow(false);
    };

    const handleShow = () => setShow(true);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type === "text/csv") {
            setFile(selectedFile);
            console.log("selectedFile:",selectedFile);
            setErrorMessage('');
        } else {
            setErrorMessage("Please select a valid CSV file.");
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!file) {
            setErrorMessage("Please select a CSV file to upload.");
            return;
        }

        try {
            const token = localStorage.getItem("userToken");
            const formData = new FormData();
            formData.append("csv_file", file, file.name);

            const response = await fetch(api_url+"/import", {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
                body: formData,
                redirect: "follow",
            });

            if (response.ok) {
                const result = await response.json();
                setSuccessMessage("CSV file uploaded successfully!");
                setErrorMessage('');
                setFile(null);
                onfetchUsers('');
                setTimeout(() => {
                    handleClose();
                }, 2000);
            } else {
                const error = await response.text();
                setSuccessMessage('');
                setErrorMessage("Failed to upload CSV file: " + error);
            }
        } catch (error) {
            setSuccessMessage('');
            setErrorMessage("An error occurred: " + error.message);
        }
    };

    return (
        <div>
            <label style={{ marginTop: '10px' }}>Users Import</label>
            <br />
            <NavLink className="btn btn-success btnWhiteGradient2" onClick={handleShow}>
                <i className="bi bi-file-earmark-excel-fill"></i> Import Users
            </NavLink>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">Import Users</Modal.Title>
                </Modal.Header>

                <Modal.Body className="addUserWrapper">
                    <div className="mb-3">
                        <label className="form-label">Select CSV File</label>
                        <input
                            type="file"
                            className="form-control"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </div>
                    {successMessage && <div className="alert alert-success">{successMessage}</div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpload}>
                        Upload CSV
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BulkUserImport;
