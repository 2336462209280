import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import LeftNav from '../../Components/LeftNav/LeftNav';
import Head from '../../Components/Head/Head';


const NotFound = () => {

    const location = useLocation();
    const [userLoggeIn, setUserLoggedIn] = useState(false);

    useEffect(() => {
        
        console.log('in use Effect of Root.js');

        if (localStorage.getItem("userToken"))
        {
            console.log('in use Effect of Root.js if condition');

            setUserLoggedIn(true);
        }
    }, [location.pathname]);

    return (
        <div className="siteContainer">
            <div className="row m-0">
                { userLoggeIn &&  <LeftNav /> }
                <div className={`siteRight ${userLoggeIn ? "col-md-9 p-0" : "col-md-12"}`}>
                    { userLoggeIn &&  <Head /> }
                    <div className="p-5">
                        <div className='alert alert-danger'>
                            The page you are looking for is not found.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default NotFound;