import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { Tabs, Tab } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditPhishingCompaign from '../../Components/EditPhishingCompaignModal/EditPhishingCompaign';

const PhishingCompaignDetail = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("details");
    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');

    // Handle delete user action
   
    // Fetch videos data with pagination
   
    
   
  

   

    // Define table columns
  

    // Create a table instance
  

    return (
        <div className="p-4">

        <div className="pageTitle mb-4">{t('Phishing Compaign Detail')}</div>

        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="details" title="Edit Phishing Compaign">
                <div>
                <EditPhishingCompaign id={id}  />
                            </div>
            </Tab>

        </Tabs>

    </div>
    );
};

export default PhishingCompaignDetail;