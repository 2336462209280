import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './EmailTemplates.css';


const EmailTemplates = () => {

    const [data, setUsers] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const [searchVal, setSearchVal] = useState('');
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)

    const handleDelete = async (Id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${Id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.ok) {
                setDeleted("Email template deleted successfully!");
                fetchEmailTemplate('');
            } else {
                setDeleted("Error! Failed to delete email template. Please try");
            }
        }
        catch (error) {
            setDeleted("Error!" + error);
        } finally {
            setLoading(false);

        }
    }

    useEffect(() => {
        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.created_at.toLowerCase().includes(searchVal.toLowerCase())
            );
            setUsers(filteredData);
        } else {
            console.log("email templates");
            fetchEmailTemplate('');
        }
    }, [searchVal]);

    const fetchEmailTemplate = async (url) => {
        setLoading(true);
        var page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setUsers(result.data);
            setLinks(result.links);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmailTemplate('');
    }, []);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'name',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Name')}
                    </div>
                ),
                colName: ' Name',
            },
            {
                accessorKey: 'created_at',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        {/* <i className="bi bi-clipboard-data"></i>{t('Created At')} */}
                        <img src="/images/fi_clock.png" alt="Created At" />{t('Created At')}
                    </div>
                ),
                colName: 'Created_at',
                cell: ({ row }) => {
                    const rawDate = row.original.created_at;
                    const formattedDate = new Date(rawDate).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric',
                    });
                    return formattedDate;
                },
            },
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <NavLink className="iconPreview" to={`/previewemailtemplate/${id}`} >
                                <i class="bi bi-search"></i> {t('Preview')}
                            </NavLink>
                            <NavLink className="iconEdit" to={`/editemailtemplate/${id}`} >
                                <img src="/images/edit.png" alt="Add Email" /> {t('Edit')}
                            </NavLink>
                            <div className="iconDel" onClick={() => {
                                setLoading(true);
                                handleDelete(id)
                                setLoading(false);
                            }}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'Action',
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('All Email Templates')}</div>

            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search Email Template')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by name"
                                aria-label="Search by name"
                                value={searchVal}
                                onChange={(e) => setSearchVal(e.target.value)}
                            />
                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <label style={{ marginTop: '10px' }}>{t('Add New Email Template')}</label>
                        <br />
                        <NavLink className="btnWhiteGradient" to="/addemailtemplate">
                            <img src="/images/fi_user-plus.png" alt="Add Email" /> {t('Add')}
                        </NavLink>
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}

            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (
                <div className="bg-light px-3 py-3 userTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row">
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        {cell.column.columnDef.colName !== "ID" ? (
                                            <strong>{cell.column.columnDef.colName}: </strong>
                                        ) : ''}
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="thead-dark">
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id}>
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id} className='rowAnchor'>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}


            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (
                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchEmailTemplate(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default EmailTemplates;
