import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddPhishingCompaign from '../../Components/AddPhishingCompaignModal/AddPhishingCompaign';
import { useTranslation } from 'react-i18next';
// import EditLDAPServer from '../../Components/EditLDAPServerModal/EditLDAPServer';
import { useNavigate } from 'react-router-dom';


const PhishingCompaign = () => {
    const [data, setPhishingCompaign] = useState([]);
    const [links, setLinks] = useState([]);
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

   
useEffect(() => {

    if (searchVal !== '') {
      const filteredData = data.filter((item) =>
      item.name_ar.toLowerCase().includes(searchVal.toLowerCase()) ||
      item.name_en.toLowerCase().includes(searchVal.toLowerCase())
    //   item.server_type.toLowerCase().includes(searchVal.toLowerCase()) ||
    //   item.security_type.toLowerCase().includes(searchVal.toLowerCase()) 
      );
      setPhishingCompaign(filteredData); // Update filtered data
       }  else{
        fetchPhishingCompaign(''); // Update filtered data
      }
      }, [searchVal]); // Re-run whenever searchVal or allData changes

  // Fetch department data
  const fetchPhishingCompaign = async (url) => {
    setLoading(true);
    let page = 1;
    if (url !== '') {
        const temp = url.split('?page=');
        page = temp[1] ? temp[1] : 1;
    }

    try {
        const token = localStorage.getItem("userToken");
        const response = await fetch(`${api_url}/phishing-campaigns?page=${page}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setPhishingCompaign(result || []); // Fallback to an empty array
        setLinks(result.links || []); // Fallback to an empty array
    } catch (error) {
        console.error('Error fetching Phishing Campaign data:', error);
        setPhishingCompaign([]); // Fallback to an empty array
        setLinks([]); // Fallback to an empty array
        setLoading(false);
    }
    finally {
        setLoading(false);
    }
};

     
     
    
  const columns = React.useMemo(
    () => [
    {
        accessorKey: 'name_ar',
        header: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
             <i class="bi bi-clipboard-data"></i>{t('Name ar')} 
            </div>
        ),
        colName: 'name_ar',

    },
    {
        accessorKey: 'name_en',
        header: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
             <i class="bi bi-clipboard-data"></i>{t('Name en')}
            </div>
        ),
        colName: 'name_en',
    },
    {
        accessorKey: 'start_date',
        header: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <i class="bi bi-clipboard-data"></i>{t('Start Date')} 
            </div>
        ),
        colName: 'start_date',

    },
    {
        accessorKey: 'end_date',
        header: () => (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
         <i class="bi bi-clipboard-data"></i>{t('End Date')}
            </div>
        ),
        colName: 'end_date',

    },
    {
      accessorKey: 'template_id',
      header: () => (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <i class="bi bi-clipboard-data"></i>{t('Template id')} 
          </div>
      ),
      colName: 'template_id',

  },
//   {
//     accessorKey: 'save_submitted_data',
//     header: () => (
//         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
//           <i class="bi bi-clipboard-data"></i>{t('Save Submitted Data')} 
//         </div>
//     ),
//     colName: 'save_submitted_data',

// },
{
    accessorKey: 'execute_url',
    header: () => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <i class="bi bi-clipboard-data"></i>{t('Execute url')} 
        </div>
    ),
    colName: 'execute_url',

},
// {
//     accessorKey: 'attachment',
//     header: () => (
//         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
//           <i class="bi bi-clipboard-data"></i>{t('attachment')} 
//         </div>
//     ),
//     colName: 'attachment',

// },



    // {
    //     accessorKey: 'id',
    //     header: () => (
    //         <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //           <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')} 
    //         </div>
    //     ),
    //     cell: ({ getValue }) => {
    //         const id = getValue();
    //         return (
    //           <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    //             <SyncModal  id={id}/>
    //             {/* <EditLDAPServer id={id}  parentfetchLDAPServer={fetchLDAPServer}/>  */}
               
    //             <div className="iconDel">
    //                 <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')} 
    //             </div>
    //           </div>
    //         );
    //     },
    //     colName: 'id',
    // },
   

    ],
    []
);

// Create a table instance
   const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
const handleSurveyRowClick = (id) => {
    navigate(`/phishinhcompaigndetail/${id}/phishinhcompaigndetail`)
}

  return (
   <div className="p-4">
               <div className="pageTitle mb-4">{t('All Phishing Compaign')}</div>
               <div className="primaryBlueBox p-5 mb-4">
                   <div className="row">
                       <div className="col-md-4">
                           <label className="form-label">{t('Search Phishing Compaign')}</label>
                           <div className="input-group">
                           <input
                               type="text"
                               className="form-control"
                               placeholder="Search by Phishing Compaign"
                               aria-label="Search by Phishing Compaign"
                               value={searchVal} // Bind input value to searchVal state
                               onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                               />              
                                <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                   <i className="bi bi-search"></i>
                               </button>
                           </div>
                       </div>
                       <div className="col-md-1">
                           <div className="vrLine"></div>
                       </div>
                       <div className="col-md-7">
                           <AddPhishingCompaign parentfetchPhishingCompaign={fetchPhishingCompaign}/>
                       </div>
                   </div>
               </div>
   
             
   
               {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}></div>
                </div>
            ) : (
                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}

              
                         <div className="navigationWrapper">
                   <ul>
                       {links.map((link, index) => (
   
                           <li key={index}
                               className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                               onClick={link.url !== null ? () => fetchPhishingCompaign(link.url) : undefined}
                           >
                               <div dangerouslySetInnerHTML={{ __html: link.label }} />
                           </li>
                       ))}
                   </ul>
               </div>
   
           </div>
  );
};

export default PhishingCompaign;
