import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import "./PreviewEmailTemplate.css";
import { api_url } from '../../constant/constant';

const PreviewEmailTemplate = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const [formData, setFormData] = useState({ name: " ", body: "" })


    const fetchEmailTemplate = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/email-templates/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.ok) {
                const { data } = await response.json();
                setFormData({ name: data.name, body: data.body });
            } else {
                throw new Error("Failed to fetch data");
            }
        }
        catch (error) {
            alert(`Error: ${error.message}`)
        }
    };

    useEffect(() => {
        if (id) fetchEmailTemplate();
    }, [id]);

    return (
        <div className='m-5'>
            <h2 className="pageTitle">{t("Preview Email Template")}</h2>
            <div className='mt-3'>
                <strong>Name:</strong> <br /> <div> {formData.name}
                    </div> 
            </div>
            <div className='mt-3'>
                <strong>Email Body:</strong>
                <div className='emailBody' dangerouslySetInnerHTML={{ __html: formData.body }}>

                </div>
            </div>
        </div>
    )
}

export default PreviewEmailTemplate
