import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditVideo.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const EditVideo = ({id, parentfetchVideo}) => {

    const { lesson_id } = useParams(); // Assuming `id` is a valid variable available in scope
    const [video_id] = useState(id); // Use camelCase for naming consistencyws
    const [show, setShow] = useState(false); // Correct syntax for declaring state
    
    const { t } = useTranslation();

    const fetchVideos= async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${video_id}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching video details.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
            
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["video"]: data.video,
                ["language"]: data.language,
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => {
        fetchVideos();
        setShow(true);
    }

    const [formData, setFormData] = useState({
        video: "",
        language: "",
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdatevideo = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${video_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });
            

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Video updated successfully!");
               
                parentfetchVideo('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update Video. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }
    
	const handleFileChange = (e) => {
		setFormData({
		  ...formData,
		  video: e.target.files[0],
		});
	};

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" /> Edit
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">Edit video</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="mb-3">
                        <label className="form-label">{t('Language')}</label>
                        <select className="form-select" aria-label="Language" name="language" onChange={handleChange}required value={formData.language}>
                            <option value="en">{t('English')}</option>
                            <option value="ar">{t('Arabic')}</option>
                        </select>
                    	</div>
                        <div className="mb-3">
                        <label className="form-label">{t('Video')}</label>
                        <input
                        type="file"
                        className='form-control'
                        accept="video/*"
                        onChange={handleFileChange}
                        required
                        />
                    </div>
                        </div>
                
                      

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={handleUpdatevideo}>
                        Update Video
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditVideo;