import React, { useEffect, useState ,useRef } from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddVideo from '../../Components/AddVideoModal/AddVideo';
import EditVideo from '../../Components/EditVideoModal/EditVideo';
import { useTranslation } from 'react-i18next';

import './Videos.css';

const Videos = ({ id }) => {
    const lesson_id = id;
    const { t } = useTranslation();

    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);
    const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleted, setDeleted] = useState('');
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

           // State to track whether the video is playing
           const [isVideoPlaying, setIsVideoPlaying] = useState(false);
           const videoRef = useRef(null);
       
           const togglePlayPause = () => {
               if (videoRef.current.paused) {
                   videoRef.current.play();
                   setIsVideoPlaying(true);
               } else {
                   videoRef.current.pause();
                   setIsVideoPlaying(false);
               }
           };

    // Handle delete user action
    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('userToken');
            const response = await fetch(`${api_url}/lessons/${lesson_id}/videos/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setDeleted('Video deleted successfully!');
                fetchVideos('');
            } else {
                setDeleted('Error!! Failed to delete Video. Please try again.');
            }
        } catch (error) {
            setDeleted("Error!! " + error);
        setLoading(false);
        } finally{
        setLoading(false);
        }
    };

    // Fetch videos data with pagination
    const fetchVideos = async (url) => {
        setLoading(true);
        let page = 1;
        if (url) {
            const temp = url.split('?page=');
            page = temp[1] || 1;
        }

        try {
            const token = localStorage.getItem('userToken');
            const response = await fetch(`${api_url}/lessons/${lesson_id}/videos?page=${page}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setVideos(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    
   
    useEffect(() => {
        fetchVideos('');
    }, []);

    const getLanguageName = (code) => {
        switch (code) {
            case 'en':
                return 'English';
            case 'ar':
                return 'Arabic';
            default:
                return 'Unknown';
        }
    };

    const handlePlayVideo = (url) => {
        setSelectedVideoUrl(url);
        setVideoPopupOpen(true);
    };
    const closeVideoPopup = () => {
        setVideoPopupOpen(false);
        setSelectedVideoUrl('');
    };

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'url',
                header: () => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '130px' }}>
                        <i className="bi bi-clipboard-data"></i>
                        {t('URL')}
                    </div>
                ),
                colName: 'Url',
            },
            {
                accessorKey: 'size',
                header: () => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <i className="bi bi-clipboard-data"></i>
                        {t('Size')}
                    </div>
                ),
                colName: 'Size',
            },
            {
                accessorKey: 'length',
                header: () => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <i className="bi bi-clipboard-data"></i>
                        {t('Length')}
                    </div>
                ),
                colName: 'Length',
            },
            {
                accessorKey: 'language',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Language')} 
                    </div>
                ),
                cell: ({getValue}) => {
                    return (
                        getLanguageName( getValue() )
                    );
                },
                colName: 'Language',
            },
          
            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />
                        {t('Action')}
                    </div>
                ),
                cell: ({ getValue, row }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div className="iconPlay" onClick={() => handlePlayVideo(row.original.url)}>
                            <i className="bi bi-play-btn"></i> Play
                        </div>
                            <EditVideo lesson_id={lesson_id} id={id} parentfetchVideo={fetchVideos} />
            
                            {/* Delete button */}
                            <div className="iconDel" onClick={() => handleDelete(id)}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />
                                {t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'ID',
            },
            
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">
        <div className="primaryBlueBox p-5 mb-4">
            <div className="row">
                <div className="col-md-4">
                    <label className="form-label">{t('Search Video')}</label>
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                            <i className="bi bi-search"></i>
                        </button>
                    </div>
                </div>
                <div className="col-md-1">
                    <div className="vrLine"></div>
                </div>
                <div className="col-md-7">
                    <AddVideo lesson_id={lesson_id} parentfetchVideo={fetchVideos} />
                </div>
            </div>
        </div>

        { deleted && (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
                {deleted}
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        )}
        {loading ? ( <div className="d-flex justify-content-center align-items-center  mt-5">
                    <div className="spinner-border loader-api" role="status" style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>) : (

        <div className="bg-light px-3 py-3 videosTableWrapper resTable">
           
           {window.innerWidth < 768 ? (
                   // Render stacked view for small screens
                   table.getRowModel().rows.map(row => (
                     <div key={row.id} className="stacked-row">
                       {row.getVisibleCells().map(cell => (
                         <div key={cell.id} className="stacked-cell">
                           {cell.column.columnDef.colName != "ID" ? (
                               <strong>{cell.column.columnDef.colName}: </strong>
                           ) : ('')}
                           
                           {flexRender(cell.column.columnDef.cell, cell.getContext())}
                         </div>
                       ))}
                     </div>
                   ))
                 ) : (
            <div className="table-responsive">
                   <table className="table table-bordered table-hover">
                       <thead className="thead-dark">
                           {table.getHeaderGroups().map(headerGroup => (
                               <tr key={headerGroup.id}>
                                   {headerGroup.headers.map(header => (
                                       <th key={header.id} id={header.id}>
                                           {flexRender(header.column.columnDef.header, header.getContext())}
                                       </th>
                                   ))}
                               </tr>
                           ))}
                       </thead>
           
                       <tbody>
                           {table.getRowModel().rows.map(row => (
                               <tr key={row.id}>
                                   {row.getVisibleCells().map(cell => (
                                       <td key={cell.id}>
                                           {flexRender(cell.column.columnDef.cell, cell.getContext())} 
                                           {cell.id.includes('size') ? ' bytes' : ''}
                                       </td>
                                   ))}
                               </tr>
                           ))}
                       </tbody>
                   </table>
               </div>
                 )}
               
           </div>
                )}
           


            <div className="navigationWrapper">
                <ul>
                {links.map((link, index) => (
                    
                    <li key={index} 
                        className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                        onClick={link.url !== null ? () => fetchVideos(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

            {/* Video Modal */}
            {isVideoPopupOpen && (
    <div className="video-popup" onClick={closeVideoPopup}>
        <div className="video-container" onClick={(e) => e.stopPropagation()}>
            {/* Close Button */}
            <button className="close-btn" onClick={closeVideoPopup}>
                &times;
            </button>
            <video
                controls={false} // Disable default controls
                autoPlay
                ref={(video) => {
                    if (video) {
                        video.addEventListener('seeked', (e) => e.preventDefault()); // Prevent seeking
                        video.addEventListener('seeking', (e) => e.preventDefault()); // Prevent seeking
                    }
                }}
            >
                <source src={selectedVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="video-custom-controls">
                {/* Custom Play/Pause Button */}
                {/* <button
                    onClick={(e) => {
                        const video = e.target.closest('video');
                        if (video.paused) {
                            video.play();
                        } else {
                            video.pause();
                        }
                    }}
                >
                    {isVideoPlaying ? 'Pause' : 'Play'}
                </button> */}
            </div>
        </div>
    </div>
)}



        </div>
    );
};

export default Videos;
