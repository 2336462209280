import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { api_url } from '../../constant/constant';
import AddLDAPServer from '../../Components/AddLDAPServerModal/AddLDAPServer';
import { useTranslation } from 'react-i18next';
// import EditLDAPServer from '../../Components/EditLDAPServerModal/EditLDAPServer';
import SyncModal from '../../Components/SyncModal/SyncModal';
import { useNavigate } from 'react-router-dom';

const LDAPServer = () => {
    const [data, setLDAPServer] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleDelete = async (id) => {
        setLoading(true);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/ldap_servers/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setDeleted("LDAP server deleted successfully!");
                fetchLDAPServer('');
            }
            else {
                setDeleted("Error!! Failed to delete LDAP server. Please try again.");
            }
        }
        catch (error) {
            setDeleted("Error!! " + error);
            setLoading(false);
        } finally {
            setLoading(false);

        }

    }
    useEffect(() => {

        if (searchVal !== '') {
            const filteredData = data.filter((item) =>
                item.title.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.host.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.server_type.toLowerCase().includes(searchVal.toLowerCase()) ||
                item.security_type.toLowerCase().includes(searchVal.toLowerCase())
            );
            setLDAPServer(filteredData); // Update filtered data
        } else {
            fetchLDAPServer(''); // Update filtered data
        }
    }, [searchVal]);

    const fetchLDAPServer = async (url) => {
        setLoading(true);
        let page = 1;
        if (url !== '') {
            const temp = url.split('?page=');
            page = temp[1];
        }

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(`${api_url}/ldap_servers?page=${page}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLDAPServer(result.data); // Update state with fetched data
            setLinks(result.links); // Update pagination links
        } catch (error) {
            console.error('Error fetching LDAP server data:', error);
            setLoading(false);
        } finally {
            setLoading(false);

        }
    };

    useEffect(() => {
        fetchLDAPServer('');
    }, []);


    useEffect(() => {
        fetchLDAPServer('');
    }, []);
    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'title',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Title')}
                    </div>
                ),
                colName: ' title',

            },
            {
                accessorKey: 'host',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Host')}
                    </div>
                ),
                colName: ' host',
            },
            {
                accessorKey: 'port',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Port')}
                    </div>
                ),
                colName: ' port',

            },
            {
                accessorKey: 'server_type',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Server type')}
                    </div>
                ),
                colName: ' server_type',

            },
            {
                accessorKey: 'security_type',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i class="bi bi-clipboard-data"></i>{t('Security type')}
                    </div>
                ),
                colName: ' security_type',

            },




            {
                accessorKey: 'id',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')}
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue();
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <SyncModal id={id} />
                            {/* <EditLDAPServer id={id}  parentfetchLDAPServer={fetchLDAPServer}/>  */}

                            <div className="iconDel" onClick={() => handleDelete(id)}>
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'id',
            },


        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });
    const handleSurveyRowClick = (id) => {
        navigate(`/ldapservermodel/${id}/LDAPServerDetail`)
    }
    return (
        <div className="p-4">
            <div className="pageTitle mb-4">{t('All LDAP Server')}</div>
            <div className="primaryBlueBox p-5 mb-4">
                <div className="row">
                    <div className="col-md-4">
                        <label className="form-label">{t('Search LDAP Server')}</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by LDAP Server"
                                aria-label="Search by LDAP Server"
                                value={searchVal} // Bind input value to searchVal state
                                onChange={(e) => setSearchVal(e.target.value)} // Update searchVal on input change
                            />                            <button className="btn btn-outline-secondary btnSearchIcon" type="button">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="vrLine"></div>
                    </div>
                    <div className="col-md-7">
                        <AddLDAPServer parentfetchLDAPServer={fetchLDAPServer} />
                    </div>
                </div>
            </div>

            {deleted && (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {deleted}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            {loading ? (
                <div className='d-flex justify-content-center align-items-center my-5'>
                    <div className='spinner-border' role='status' style={{
                        borderTopColor: "#6897CB",
                        borderBottomColor: "#6897CB",
                        borderLeftColor: "#6897CB",
                        marginTop: "50px"
                    }}>
                    </div>
                </div>
            ) : (

                <div className="bg-light px-3 py-3 lessonsTableWrapper resTable">
                    {window.innerWidth < 768 ? (
                        table.getRowModel().rows.map(row => (
                            <div key={row.id} className="stacked-row"
                                onClick={row.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                {row.getVisibleCells().map(cell => (
                                    <div key={cell.id} className="stacked-cell">
                                        <strong className='me-1'>{cell.column.columnDef.colName}:</strong>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <table className="table table-bordered table-hover">
                            <thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <th key={header.id}>
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td key={cell.id}
                                                className='rowAnchor'
                                                onClick={cell.id.includes('id') === false ? () => handleSurveyRowClick(row.original.id) : null}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}



            <div className="navigationWrapper">
                <ul>
                    {links.map((link, index) => (

                        <li key={index}
                            className={`${link.active ? " active " : ""} ${link.url === null ? " disabled " : ""}`}
                            onClick={link.url !== null ? () => fetchLDAPServer(link.url) : undefined}
                        >
                            <div dangerouslySetInnerHTML={{ __html: link.label }} />
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
}

export default LDAPServer;