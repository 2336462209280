import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditEmail.css';
import { useTranslation } from 'react-i18next';

const EditEmail = ({id, onfetchEmail}) => {

    const { t } = useTranslation();



    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const [userId] = useState(id);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        name: "",
        username: "",
        password: "",
        port: "",
        encryption: "",
        host: "",
       
    });

    const fetchEmailData = async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(api_url + "/email-servers/" + userId, {
				mode: 'cors',
				method: "GET",
			  	headers: {
					"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
			  	}
			});
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching user details.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["name"]: data.name,
                ["username"]: data.username,
                ["password"]: data.password,
                ["port"]: data.port,
                ["host"]: data.host,
                ["encryption"]: data.encryption,
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    useEffect(() => {
        fetchEmailData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleEditEmail = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/email-servers/" + userId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Email Server updated successfully!");
                onfetchEmail('');
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update Email Server. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div>

            <div className="iconEdit" onClick={handleShow}>
                <img src="/images/edit.png" alt="Edit" />{t('Edit')} 
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="pageTitle">{t('Edit Email Server ')}</Modal.Title>
                </Modal.Header>

                <Modal.Body  className="addUserWrapper">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Name')}</label>
                                <input type="text" name="name" className="form-control" onChange={handleChange} value={formData.name} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Username')}</label>
                                <input type="email" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Password')}</label>
                                <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Host')}</label>
                                <input type="host" name="host" className="form-control" onChange={handleChange} value={formData.host} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Port')}</label>
                                <input type="text" name="port" className="form-control" onChange={handleChange} value={formData.port} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Encryption')}</label>
                                <input type="encryption" name="encryption" className="form-control" onChange={handleChange} value={formData.encryption} />
                            </div>
                        </div>
                    
                        <div className="col-md-6">
                            <div className="mb-3">
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {/* <div className="mb-3">
                                <label className="form-label">Role</label>
                                <select className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Supersoft</option>
                                    <option value="2">Professional Service</option>
                                    <option value="3">Admin</option>
                                </select>
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            {/* <div className="mb-3">
                                <label className="form-label">Status</label>
                                <select className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                            </div> */}
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    {t('Close')}
                    </Button>

                    <Button variant="primary" onClick={handleEditEmail}>
                    {t('Update Email Server')}  
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
      );

}

export default EditEmail;