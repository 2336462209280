import React, { useEffect, useState } from 'react';
import { api_url } from '../../constant/constant';
import './Login.css';
import { useTranslation } from 'react-i18next';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [captchaEnabled, setCaptchaEnabled] = useState(false);
  const [sso_enabled, setSSOEnabled] = useState('');
  const [system_allowed, setSystemAllowed] = useState(true);
  const [system_language, setSystemLanguage] = useState('');
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [captchaImage, setCaptchaImage] = useState(null);  // For CAPTCHA image
  const [captchaResponse, setCaptchaResponse] = useState('');  // For storing user CAPTCHA input
  const [captchaId, setCaptchaId] = useState(null);
  const [twoFA, setTwoFA] = useState(false);
  const [twoFaVal, setTwoFaVal] = useState('');
  // const onChangeRecaptcha = (value) => {
  //   setRecaptchaToken(value); // Save ReCAPTCHA token
  //   console.log("ReCAPTCHA Value:", value);
  // };

  const validateCaptcha = async (captchaId) => {
    try {
      const response = await fetch(`${api_url}/captcha/validate`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          captcha_id: captchaId,
          captcha: captchaResponse,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "CAPTCHA validation failed.");

      }

      const data = await response.json();
      return data; // Return the full response object, including success status
    } catch (err) {
      console.error("CAPTCHA validation error:", err.message);
      setError(err.message);
      return { success: false }; // Indicate failure
    }
  };

  const validateTwoFA = async () => {
    try {
      const response = await fetch(`${api_url}/verify-2fa`, {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          two_factor_code: twoFaVal,
          email: email,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setLoading(false);
        setError(errorData.message);
        // throw new Error(errorData.message || "2FA validation failed.");
        return false;
      }

      const data = await response.json();
      setLoading(false);
      // return data; // Return the full response object, including success status
      if (data.user?.force_reset_password === 1) {
        console.log(data.user);
        console.log("force password");
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));
        window.location = "/updatepassword";
        return false;
      }
      if (data.user.role_id === 5) {
        // Handle assessment-required case
        localStorage.setItem("dashboard", JSON.stringify(data.dashboard));
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));
        if (data.assessment_required == true) {
          localStorage.setItem("questions", JSON.stringify(data.questions));
          window.location = "/Assessment";
        }
      } else {
        // Handle normal login
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));

        // Redirect to dashboard
        window.location = "/dashboard";
      }
    } catch (err) {
      console.error("CAPTCHA validation error:", err.message);
      setError(err.message);
      return { success: false }; // Indicate failure
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    if (captchaEnabled && !captchaResponse) {
      setError("Please complete the CAPTCHA verification.");
      setLoading(false);
      return;
    }

    try {
      // Validate captcha first
      if (captchaEnabled) {
        const captchaValidation = await validateCaptcha(captchaId); // Use the captcha validation function
        if (!captchaValidation.success) {
          setError("CAPTCHA does not match");
          setLoading(false);
          return;
        }
      }

      if (twoFA) {
        await validateTwoFA(); // Use the captcha validation function
        return false;
      }

      // Proceed with login request
      const response = await fetch(api_url + "/login", {
        mode: "cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          login: email,
          password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.captcha_image) {
          setCaptchaEnabled(true);

          setCaptchaImage(errorData.captcha_image.original.captcha);
          setCaptchaId(errorData.captcha_image.original.captcha_id)
        }
        if (errorData.requires_gender_selection) {
          // Redirect to gender selection page or prompt
          localStorage.user_id = errorData.user.user_id;
          window.location = "/Updategender";
          return;
        }
        setError(errorData.message || "Login failed. Check your credentials.");
        throw new Error(errorData.message);
      }

      const data = await response.json();

      setError("");
      if (data?.two_factor_required === true) {
        setLoading(false);
        setTwoFA(true);
        return false;
      }
      if (data.user?.force_reset_password === 1) {
        console.log(data.user);
        console.log("force password");
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));
        window.location = "/updatepassword";
        return false;
      }
      if (data.user.role_id === 5) {
        // Handle assessment-required case
        localStorage.setItem("dashboard", JSON.stringify(data.dashboard));
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));
        if (data.assessment_required == true) {
          localStorage.setItem("questions", JSON.stringify(data.questions));
          window.location = "/Assessment";
        }
      } else {
        // Handle normal login
        localStorage.setItem("userToken", data.token);
        localStorage.setItem("userData", JSON.stringify(data.user));

        // Redirect to dashboard
        window.location = "/dashboard";
      }
    } catch (err) {
      console.error(err.message);
      setError(err.message);
      setLoading(false);
    }
  };

  const loginSettings = async () => {
    try {
      const response = await fetch(api_url + "/login-info", {
        mode: 'cors',
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error getting login settings...");
      }

      const data = await response.json();
      console.log("Captcha Image Data:", data.captcha_image); // Log the CAPTCHA image data

      const lng = data.language_code;
      localStorage.setItem("i18nextLng", lng);
      i18n.changeLanguage(lng);
      document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
      document.getElementsByTagName("body")[0].setAttribute("dir", lng === "ar" ? "rtl" : "ltr");
      setSystemLanguage(data.language_id);
      setSystemAllowed(data.system_allowed);
      setSSOEnabled(1);

      // Check if CAPTCHA is enabled and base64 string is valid
      if (data.captcha_enabled) {
        const fullCaptchaImage = `data:image/png;base64,${data.captcha_image}`;
        console.log("Full CAPTCHA Image URL:", fullCaptchaImage); // Debug log
        setCaptchaImage(fullCaptchaImage);
      }

    } catch (err) {
      console.log(err.message);
    }
  };


  const updateSystemLanguage = async (language_code) => {
    try {
      const response = await fetch(api_url + "/settings/language", {
        mode: 'cors',
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ language_id: language_code }),
      });

      if (!response.ok) {
        throw new Error("Error getting login settings...");
      }

      const data = await response.json();
      console.log(data);

      if (data.success) {
        loginSettings();
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    loginSettings();
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="loginLogo mt-5" align="center">
            <img src="/images/logo.png" alt="Cyber Sense" />
          </div>
          <div className="card mt-5 pb-5" style={{ borderRadius: '30px' }}>
            <div className="card-body">
              <h2 className="m-5 txtLoginAccount" align="center">{t('Login account')}</h2>

              {error && (
                <div className="alert alert-danger mx-5">{error}</div>
              )}

              {system_allowed ? (
                <div>
                  <div className="ps-5 pe-5">
                    <form onSubmit={handleSubmit} className="logoForm">
                      <div className="form-group mb-3">
                        <label htmlFor="email">{t('Email')}</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="password">{t('Password')}</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>

                      {/* CAPTCHA Section */}
                      {captchaImage && (
                        <div className="mb-3">
                          <label htmlFor="captcha">{t('Enter CAPTCHA')}</label>
                          <div>
                            <div>
                              <img src={`${captchaImage}`} alt="CAPTCHA" className="captcha-image" />
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control mt-2"
                            id="captcha"
                            value={captchaResponse}
                            onChange={(e) => setCaptchaResponse(e.target.value)}
                            required
                          />
                        </div>
                      )}

                      {twoFA && (
                        <div className="mb-3">
                          <label htmlFor="captcha">{t('Enter 2FA Code')}</label>

                          <input
                            type="text"
                            className="form-control mt-2"
                            id="twoFA"
                            value={twoFaVal}
                            onChange={(e) => setTwoFaVal(e.target.value)}
                            required
                          />
                        </div>
                      )}

                      <button
                        type="submit"
                        className="btn btn-dark w-100 p-2"
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          t('Login Account')
                        )}
                      </button>
                    </form>

                    <div className="mt-3 mb-3 text-aha text-center">
                      {t("Don't have account?")} <a href="#register">{t('Sign up')}</a>
                    </div>

                    {sso_enabled === 1 && (
                      <div>
                        <div className="line-text">
                          <div className="line"></div>
                          <span className="text">{t('OR')}</span>
                          <div className="line"></div>
                        </div>

                        <div className="loginGoogle mt-3" align="center">
                          <img src="/images/signupgoogle.png" alt="Signup with Google" />
                        </div>

                        <div className="loginMicrosoft mt-2" align="center">
                          <img src="/images/signupmicrosoft.png" alt="Signup with Microsoft 365" />
                        </div>
                      </div>
                    )}

                    <div className="py-3">
                      <select
                        className="form-select"
                        value={system_language}
                        onChange={(e) => updateSystemLanguage(e.target.value)}
                      >
                        <option value="1">English</option>
                        <option value="2">العربی</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="alert alert-danger">{t('System License Expired, Please Contact System Admin')}</div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-3 mb-3 text-aha text-center">
            {t('&copy; 2025 All rights reserved.')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
