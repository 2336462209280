import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditUser.css';
import { useTranslation } from 'react-i18next';

const EditUser = ({id, onfetchUsers}) => {

    const { t } = useTranslation();

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [roles, setRoles] = useState([]); // State for roles  
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setSuccessMessage('');
        setErrorMessage('');
        setShow(false);
        setFormData({
            ...formData,
            ["firstname"]: '',
            ["lastname"]: '',
            ["email"]: '',
            ["phone_number"]: '',
            ["username"]: '',
            ["role_id"]: '',
            ["password"]: '',
            ["status_id"]: '',
        });
    };

    // const handleShow = () => {
    //     fetchUserData();
    //     fetchRoles();
    //     setShow(true);
    // }

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        username: "",
        role_id: "",
        password: "",
        status_id: "",
        source_id: "2"
    });
 const fetchUserData = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/admin/main/users/" + id, {
                mode: 'cors',
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                setErrorMessage("Error fetching user details.");
                setSuccessMessage("");
            }

            const data = await response.json();
            setErrorMessage("");
            setSuccessMessage("");

            setFormData({
                ...formData,
                ["firstname"]: data.firstname,
                ["lastname"]: data.lastname,
                ["email"]: data.email,
                ["phone_number"]: data.phone_number,
                ["username"]: data.username,
                ["role_id"]: data.role_id,
                ["password"]: data.password,
                ["status_id"]: data.status_id,
            });

        } catch (err) {
            setErrorMessage(err.message);
            setSuccessMessage("");
        }
    }
    useEffect(() => {
        fetchRoles();
        fetchUserData();
    }, []);

 const fetchRoles = async () => {
        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/admin/main/user/roles', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                setErrorMessage("Error fetching roles.");
                return;
            }

            const rolesData = await response.json();
            setRoles(rolesData); // Assuming API returns an array of roles
        } catch (err) {
            setErrorMessage(err.message);
        }
    };
  
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,

            [name]: value,
        });
    };

    const handleEditUser = async (e) => {
          e.preventDefault();
  
          const updateFormData = {...formData};
          if (!formData.password || formData.password.trim() === "") {
              delete updateFormData.password;
          }
  
          try {
              const token = localStorage.getItem("userToken");
              const response = await fetch(api_url + "/admin/main/users/update/" + id, {
                  method: "PUT",
                  headers: {
                      "Content-Type": "application/json",
                      "Authorization": `Bearer ${token}`,
                  },
                  body: JSON.stringify(updateFormData),
                  redirect: "follow"
              });
  
              if (response.ok) {
                  setErrorMessage("");
                  setSuccessMessage("User updated successfully!");
                  onfetchUsers(''); // Refresh the user list
              } else {
                  setSuccessMessage("");
                  setErrorMessage("Failed to update user. Please try again.");
              }
          }
          catch (error) {
              setSuccessMessage("");
              setErrorMessage(error);
          }
      }

    return (
        <div className="py-4">
             

<div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('First Name')}</label>
                                <input type="text" name="firstname" className="form-control" onChange={handleChange} value={formData.firstname} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Last Name')}</label>
                                <input type="text" name="lastname" className="form-control" onChange={handleChange} value={formData.lastname} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Email')}</label>
                                <input type="email" name="email" className="form-control" onChange={handleChange} value={formData.email} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Phone Number')}</label>
                                <input type="text" name="phone_number" className="form-control" onChange={handleChange} value={formData.phone_number} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Username')}</label>
                                <input type="text" name="username" className="form-control" onChange={handleChange} value={formData.username} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Password')}</label>
                                <input type="password" name="password" className="form-control" onChange={handleChange} value={formData.password} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Role')}</label>
                                <select value={formData.role_id} className="form-select" aria-label="Admin" name="role_id" onChange={handleChange} required>
                                    <option value="">Select Role</option>
                                    {roles.map(role => (
                                        <option key={role.id} value={role.id}>{role.title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">{t('Status')}</label>
                                <select value={formData.status_id} className="form-select" aria-label="Active" name="status_id" onChange={handleChange} required>
                                    <option value=""></option>
                                    <option value="1">{t('Active')}</option>
                                    <option value="2">{t('Inactive')}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </div>

            <Button variant="primary" onClick={handleEditUser}>
            {t('Update User')}
            </Button>
        </div>
      );

}

export default EditUser;