import React, { useEffect, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
import { Tabs, Tab } from "react-bootstrap";
import { api_url } from '../../constant/constant';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LcLesson from '../../Components/LcLessonModal/LcLesson';
import LcUserModal from '../../Components/LcUserModal/LcUserModal';
import EditLeraningManager from '../../Components/EditLearningManager/EditLeraningManager';
import EmailTemplate from'../../Components/EmailTemplateModal/EmailTemplate';

const LearningCompaignDetail = () => {
    const { id } = useParams(); // Ensure the route contains a parameter named 'id'

    const { t } = useTranslation();
    const [key, setKey] = useState("details");
    // State variables
    const [data, setVideos] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleted, setDeleted] = useState('');

    // Handle delete user action
   
    // Fetch videos data with pagination
   
    
   
  

    const getLanguageName = (code) => {
        switch (code) {
            case 'en':
                return 'English';
            case 'ar':
                return 'Arabic';
                case 'ar':
                return 'Arabic';
            default:
                return 'Unknown';
        }
    }

    // Define table columns
    const columns = React.useMemo(
        () => [
            {
                accessorKey: 'size',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Size')}
                    </div>
                ),
                colName: 'Size',
            },
            {
                accessorKey: 'language',
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <i className="bi bi-clipboard-data"></i>{t('Language')}
                    </div>
                ),
                cell: ({getValue}) => {
                    return (
                        getLanguageName( getValue() )
                    );
                },
                colName: 'Language',
            },
          
            {
                accessorKey: 'id', // Correct accessor key
                header: () => (
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <img src="/images/fi_alert-octagon.png" alt="Source" />{t('Action')} 
                    </div>
                ),
                cell: ({ getValue }) => {
                    const id = getValue(); // Extract the value of the 'id' column
                    return (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          
                            <div className="iconPlay">
                                <i class="bi bi-play-btn"></i> {t('Play')}
                            </div>

                          
                            <div className="iconDel" >
                                <img src="/images/fi_trash-2.png" alt="Delete" />{t('Delete')}
                            </div>
                        </div>
                    );
                },
                colName: 'Action', // Correct property spelling
            },
            
        ],
        []
    );

    // Create a table instance
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <div className="p-4">

        <div className="pageTitle mb-4">{t('Learning Compaign Detail')}</div>

        <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
        >
            <Tab eventKey="details" title="Learning Compaign Detail">
                <div>
                <EditLeraningManager id={id}  />
                            </div>
            </Tab>

           

            <Tab eventKey="Lesson" title="Lesson">
                <div>
                <LcLesson LearningManager_id={id} /> 
                </div>
            </Tab>
            <Tab eventKey="User" title="User">
                <div>
                <LcUserModal LearningManager_id={id} /> 
                </div>
            </Tab>
            <Tab eventKey="Email Setting" title="Email Setting">
                <div>
                    <EmailTemplate LearningManager_id={id}/>
                </div>
            </Tab>
        </Tabs>

    </div>
    );
};

export default LearningCompaignDetail;