import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { api_url } from "../../constant/constant";
import './EditLesson.css';
import { useTranslation } from 'react-i18next';

const EditLesson = ({id,parentfetchLessons}) => {
    const { t } = useTranslation();



    const [lessonId] = useState(id);
    const [lesson_types, setLessonTypes] = useState([]);

    const fetchLessonTypes = async () => {

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + '/lesson-types', {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`, 
                },
            });
          
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            setLessonTypes(result);
            
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLesson = async() => {

        try 
		{
            const token = localStorage.getItem("userToken");
			const response = await fetch(api_url + "/lessons/" + lessonId, {
				mode: 'cors',
				method: "GET",
			  	headers: {
					"Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
			  	}
			});
	  
			if (!response.ok) 
			{
				setErrorMessage("Error fetching lesson details.");
                setSuccessMessage("");
			}
	  
			const data = await response.json();
            
			setErrorMessage("");
            setSuccessMessage("");
			
            setFormData({
                ...formData,
                ["title_ar"]: data.title_ar,
                ["title_en"]: data.title_en,
                ["description_ar"]: data.description_ar,
                ["description_en"]: data.description_en,
                ["code"]: data.code,
                ["lesson_type_id"]: data.lesson_type_id
            });

		} 
		catch (err) 
		{
			setErrorMessage(err.message);
            setSuccessMessage("");
		}
    }

    useEffect(() => {
        fetchLessonTypes();
        fetchLesson();
    }, []);

    const [formData, setFormData] = useState({
        title_ar: "",
        title_en: "",
        description_ar: "",
        description_en: "",
        code: "",
        lesson_type_id: ""
      });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleUpdateLesson = async (e) => {
        e.preventDefault();

        console.log(formData);

        try {
            const token = localStorage.getItem("userToken");
            const response = await fetch(api_url + "/lessons/" + lessonId, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, 
                },
                body: JSON.stringify(formData),
                redirect: "follow"
            });

            if (response.ok) 
            {
                setErrorMessage("");
                setSuccessMessage("Lesson updated successfully!");
                
            } 
            else 
            {
                setSuccessMessage("");
                setErrorMessage("Failed to update lesson. Please try again.");
            }
        }
        catch (error) {
            setSuccessMessage("");
            setErrorMessage(error);
        }
    }

    return (
        <div className="py-4">

            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Title (English)')}</label>
                        <input type="text" name="title_en" className="form-control" onChange={handleChange} value={formData.title_en} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Title (Arabic)')}</label>
                        <input type="text" name="title_ar" className="form-control" onChange={handleChange} value={formData.title_ar} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Description (English)')}</label>
                        <textarea name="description_en" className="form-control" onChange={handleChange}>{formData.description_en}</textarea>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Description (Arabic)')}</label>
                        <textarea name="description_ar" className="form-control" onChange={handleChange}>{formData.description_ar}</textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Code')}</label>
                        <input type="text" name="code" className="form-control" onChange={handleChange} value={formData.code} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">{t('Lesson Type')}</label>
                        <select name="lesson_type_id" className="form-select" onChange={handleChange} required value={formData.lesson_type_id}>
                            <option></option>
                            {lesson_types && lesson_types.map((lesson, index) => (
                                <option value={lesson.id}>{lesson.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="row">
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>

            <Button variant="primary" onClick={handleUpdateLesson}>
            {t('Update Lesson')}
            </Button>
        </div>
      );

}

export default EditLesson;